import type { ValueFrom } from 'components/Filter/Types'
import { type Action, Actions, type FormContextType, type FormElement, type FormState } from 'contexts/Types'
import { type ReactNode, createContext, useContext, useEffect, useReducer, useRef } from 'react'

const FormContext = createContext<FormContextType | undefined>(undefined)

const format = (state: FormState) =>
  Object.entries(state).reduce((acc, [key, el]) => {
    acc[key] = { ...el, pristine: true, error: '' }

    return acc
  }, {} as FormState)

const formReducer = (state: FormState, { type, payload }: Action): FormState => {
  switch (type) {
    case Actions.UPDATE_VALUE: {
      if (!payload) return state
      const { id, value } = payload
      const newState = { ...state }

      if (state[id]) {
        newState[id] = {
          ...state[id],
          value,
          pristine: false,
        }
      }
      return newState
    }

    case Actions.CREATE_STATE: {
      if (!payload) return state
      const newState = format(payload)
      for (const key in payload) {
        newState[key] = {
          ...(state[key] ? state[key] : {}),
          ...payload[key],
        }
      }
      return {
        ...newState,
      }
    }

    default:
      return state
  }
}

const FormProvider = ({
  children,
  init,
}: {
  children: ReactNode
  init?: FormState
}) => {
  const [form, dispatch] = useReducer(formReducer, init || {}, format)

  const dispatchValue = (id: string, value: ValueFrom<FormElement>) => {
    if (!form[id]) {
      console.error(`Form element with id ${id} does not exist. Ignoring.`)
      return
    }
    console.log('dispatching', id, value)
    dispatch({
      type: Actions.UPDATE_VALUE,
      payload: { id, value },
    })
  }

  const dispatchState = (form: FormState) =>
    dispatch({
      type: Actions.CREATE_STATE,
      payload: form,
    })

  return <FormContext.Provider value={{ form, dispatchState, dispatchValue }}>{children}</FormContext.Provider>
}

const useForm = (initialForm?: FormState) => {
  const formContext = useContext(FormContext)
  const isInitialRender = useRef(true)

  if (formContext === undefined) {
    throw new Error('The form must be used within a FormProvider')
  }

  useEffect(() => {
    if (initialForm && isInitialRender.current) {
      formContext.dispatchState(initialForm)
      isInitialRender.current = false
    }
  }, [initialForm, formContext])

  return formContext
}

export { FormProvider, useForm }
