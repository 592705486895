import DatasetIcon from '@mui/icons-material/Dataset'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import FileDownloadDoneIcon from '@mui/icons-material/FileDownloadDone'
import TuneIcon from '@mui/icons-material/Tune'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import { Accordion, AccordionSummary, Box, Loader, Tab, Tabs, Typography } from '@northvolt/ui'
import { type Table, tableFromIPC } from 'apache-arrow'
import { type AttributeCollectionLight, MaterialType } from 'client/model/'
import { useLoadWombatFromCollectionSamples } from 'client/wombat/wombat'
import type React from 'react'
import { useEffect, useState } from 'react'
import AdhocSelector from './AdhocSelector'
import type { DataLoadingParams, SelectableAttributeCollection } from './DataLoaderTypes'
import DatasetSelector from './DatasetSelector'

import { DataType } from 'components/Utils'
import { parseAsInteger, useQueryParam } from './../useQueryHook'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props
  return (
    <Box
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {children}
    </Box>
  )
}

interface DataLoaderProps {
  title: string
  table: Table | undefined
  setTable: (table: Table) => void
  setAttributeCollections: (attributeCollections: SelectableAttributeCollection[]) => void
  expanded: boolean
  setExpanded: (expanded: boolean) => void
  setParams?: (dataLoadingParams: DataLoadingParams) => void
  icon?: React.ReactNode
}

const DataLoader: React.FC<DataLoaderProps> = ({
  title,
  table,
  setTable,
  setAttributeCollections,
  expanded,
  setExpanded,
  setParams,
  icon,
}) => {
  const [tabValue, setTabValue] = useQueryParam('tab', 0, parseAsInteger)
  const [dataLoadingParams, setDataLoadingParams] = useState<DataLoadingParams>({
    grain: MaterialType.Prismatic_Cell,
    row_limit: 1000,
    randomize: false,
    filters: [],
    name: '',
  })
  const [statusMessage, setStatusMessage] = useState<string>('')
  const dataLoader = useLoadWombatFromCollectionSamples({
    axios: { responseType: 'arraybuffer' },
  })

  const downloadData = () => {
    if (
      dataLoadingParams?.grain &&
      dataLoadingParams?.row_limit &&
      dataLoadingParams?.attribute_collections &&
      dataLoadingParams?.samples &&
      dataLoadingParams?.samples &&
      dataLoadingParams?.filters
    ) {
      dataLoader.mutate({
        data: {
          grain: dataLoadingParams.grain,
          row_limit: dataLoadingParams.row_limit,
          attribute_collection_ids: dataLoadingParams.attribute_collections.map(ac => ac.id),
          sample_ids: dataLoadingParams.samples.map(s => s.id),
          randomize: dataLoadingParams.randomize,
          filters: dataLoadingParams.filters,
          stratification_col_unique_name: dataLoadingParams.stratification_col_unique_name,
        },
      })
    }
  }

  useEffect(() => {
    if (dataLoadingParams) {
      downloadData()
      if (setParams) {
        setParams(dataLoadingParams)
      }
    }
  }, [dataLoadingParams])

  const handleTabChange = (_: any, newValue: number) => {
    setTabValue(newValue)
  }

  const handleExpand = () => {
    setExpanded(!expanded)
  }

  const getVirtualAttributes = () => {
    const virtualCollection: SelectableAttributeCollection = {
      name: 'sample',
      grain: MaterialType.Any,
      attributes: [
        {
          id: -1,
          column_name: 'sample_name',
          data_type: DataType.STRING,
          unique_name: 'sample_name',
          grain: MaterialType.Any,
          warning: false,
        },
      ],
    }
    return virtualCollection
  }

  // TODO: done in backend?
  function addGenealogyAttributes(attributeCollections: AttributeCollectionLight[]): SelectableAttributeCollection[] {
    if (dataLoadingParams?.grain === 'Prismatic Cell') {
      const modifiedCollections = attributeCollections.map(collection => {
        const attribs = collection.attributes ? collection.attributes : []
        collection.attributes = attribs.flatMap(attr => {
          if (attr.grain === 'Prismatic Jelly Roll') {
            return [
              { ...attr, unique_name: `jr_a_${attr.unique_name}` },
              { ...attr, unique_name: `jr_b_${attr.unique_name}` },
            ]
          }
          return [attr]
        })
        return collection
      })
      return modifiedCollections
    }
    return attributeCollections
  }

  function checkForMissingData(
    table: Table,
    attributeCollections: SelectableAttributeCollection[],
  ): SelectableAttributeCollection[] {
    return attributeCollections.map(collection => {
      const attribs = collection.attributes ? collection.attributes : []
      return {
        ...collection,
        attributes: attribs.map(attr => {
          const col = table.select([attr.unique_name])
          const nullCount = col.data.map((x: any) => x.children[0].nullCount).reduce((a: any, b: any) => a + b, 0)
          if (col.numRows < 1 || nullCount / table.numRows > 0.95) {
            return {
              ...attr,
              warning: true,
              warningReason: 'This column has >95 % missing data',
            }
          }
          return { ...attr, warning: false }
        }),
      }
    })
  }

  useEffect(() => {
    if (dataLoader.isSuccess && dataLoader.data?.data) {
      const table = tableFromIPC(dataLoader.data.data)
      setStatusMessage(
        `${dataLoadingParams?.name} - Loaded ${table?.numCols} Attributes for ${table?.numRows} ${dataLoadingParams.grain}s`,
      )
      setTable(table)
      const extendedCollections = addGenealogyAttributes(
        dataLoadingParams.attribute_collections ? dataLoadingParams.attribute_collections : [],
      )
      const cleanedCollections = checkForMissingData(table, extendedCollections)
      const allCollections = cleanedCollections.concat(getVirtualAttributes())
      setAttributeCollections(allCollections)
    }
  }, [dataLoader.isSuccess, dataLoader.data])

  const loadingStatus = () => {
    if (dataLoadingParams && dataLoader.isLoading) {
      return (
        <Box display='flex' justifyContent='center' sx={{ py: 1, width: '100%' }}>
          <Loader type='linear' size='large' />
        </Box>
      )
    }
    if (dataLoader.isError) {
      return (
        <Box display='flex' justifyContent='center' alignItems='center' sx={{ py: 1, width: '100%' }}>
          <Box sx={{ flexDirection: 'column', textAlign: 'center' }}>
            <Typography variant='overline'>Error downloading data:</Typography>
            <Typography variant='bodyMedium'>{dataLoader?.error?.message}</Typography>
          </Box>
        </Box>
      )
    }
    return <></>
  }

  return (
    <Accordion expanded={expanded} disableGutters>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} onClick={handleExpand}>
        <Box sx={{ pr: 1, display: 'flex', alignItems: 'center' }}>{icon}</Box>
        <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
          <Typography variant='headlineSmall'>{title} / Data Selection</Typography>
          {table && (
            <Typography variant='subtitleSmall' sx={{ ml: 2, display: 'flex', alignItems: 'center' }}>
              {statusMessage}
              <FileDownloadDoneIcon />
            </Typography>
          )}
        </Box>
      </AccordionSummary>
      <Box sx={{ px: 2, pb: 2 }}>
        <Tabs value={tabValue} onChange={handleTabChange} sx={{ mb: 1 }}>
          <Tab label='Dataset' icon={<DatasetIcon />} iconPosition='start' sx={{ minHeight: 30 }} />
          <Tab label='Ad-hoc' icon={<TuneIcon />} iconPosition='start' sx={{ minHeight: 30 }} />
          <Tab label='Upload File' disabled icon={<UploadFileIcon />} iconPosition='start' sx={{ minHeight: 30 }} />
        </Tabs>
        <CustomTabPanel value={tabValue} index={0}>
          <DatasetSelector
            // datasets={datasetLoader.data?.data.sort((a, b) => a.name.localeCompare(b.name))}
            dataLoadingParams={dataLoadingParams}
            setDataLoadingParams={setDataLoadingParams}
          />
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={1}>
          <AdhocSelector dataLoadingParams={dataLoadingParams} setDataLoadingParams={setDataLoadingParams} />
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={2}>
          {/* <CsvUploader setTable={setTable} setAttributes={setAttributes} /> */}
        </CustomTabPanel>
      </Box>
      {loadingStatus()}
    </Accordion>
  )
}

export default DataLoader
