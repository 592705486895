import { FormControl } from '@mui/material'
import { MenuItem, Select, Tooltip } from '@northvolt/ui'
import { MaterialType } from 'client/model'
import { allowedGrains } from 'components/Utils'
import type React from 'react'

interface GrainSelectorProps {
  grain: MaterialType
  setGrain: (grain: MaterialType) => void
  disabled?: boolean
}

const GrainSelector: React.FC<GrainSelectorProps> = ({ grain, setGrain, disabled }) => {
  return (
    <Tooltip placement='top-start' title={'The type of item (e.g. cell) that one row of data represents.'}>
      <FormControl fullWidth>
        <Select
          id='grain'
          label='Grain'
          labelId='grain-label'
          size='small'
          value={grain}
          onChange={({ target: { value } }) => setGrain(value as MaterialType)}
          defaultValue={MaterialType.Prismatic_Cell}
          fullWidth
          disabled={disabled}
          required>
          {Object.values(MaterialType).map(materialType => (
            <MenuItem key={materialType} value={materialType} disabled={!allowedGrains.includes(materialType)}>
              {materialType}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Tooltip>
  )
}

export default GrainSelector
