import * as vg from '@uwdata/vgplot'
import type { ChangeDetectionResult } from 'client/model'
import { useEffect, useRef, useState } from 'react'
import styles from './change-detector.module.scss'

const Plot = ({
  data,
  tableName,
}: {
  data: ChangeDetectionResult
  tableName: string
}) => {
  const plotRef = useRef<any>(null)
  const [width, setWidth] = useState<number>(plotRef.current?.offsetWidth)

  useEffect(() => {
    const _brush = vg.Selection.single()
    const _brushEnd = vg.Param.value(null)
    _brush.addEventListener('value', (brushValues: any) => {
      if (brushValues && brushValues.length > 1) {
        _brushEnd.update(brushValues[1])
      }
    })

    const outlierIds = data.outliers.map(o => `'${o.nv_id}'`)
    const isOutlier = vg.sql`${vg.column('core_cells_cell_id')} IN (${outlierIds?.join(', ')})`
    const outlierFill = outlierIds?.length > 0 ? isOutlier : '#58b7ff'
    const $colors = vg.Param.array(['#58b7ff', '#f98227'])
    const $domain = vg.Param.array([false, true])

    const plot = vg.plot(
      vg.marginTop(30),
      vg.marginBottom(50),
      vg.line(vg.from(tableName), {
        x: vg.sql`CAST(${vg.column(data.time_attribute_name)} AS TIMESTAMP)`,
        y: data.attribute_name,
        tip: true,
        stroke: 'rgba(0,0,0,.1)',
      }),
      vg.dot(vg.from(tableName), {
        x: vg.sql`CAST(${vg.column(data.time_attribute_name)} AS TIMESTAMP)`,
        y: data.attribute_name,
        r: 2.5,
        fill: outlierFill,
      }),
      vg.regressionY(vg.from(tableName), {
        x: vg.sql`epoch_ms(CAST(${vg.column(data.time_attribute_name)} AS TIMESTAMP))`,
        y: data.attribute_name,
        stroke: '#f98227',
      }),
      vg.intervalX({ as: _brush }),
      vg.textX([_brush], {
        x: _brush,
        text: (p: any) => p?.value[0],
        frameAnchor: 'top',
        lineAnchor: 'bottom',
        dy: -5,
      }),
      vg.textX([_brushEnd], {
        x: _brushEnd,
        text: (p: any) => p?.value,
        frameAnchor: 'top',
        lineAnchor: 'bottom',
        dy: -20,
      }),
      vg.colorDomain($domain),
      vg.colorRange($colors),
      vg.ruleX(
        data.change_points.map(({ changed_at }) => new Date(changed_at).getTime()),
        { stroke: '#f98227', strokeWidth: 2, strokeDasharray: '5,5' },
      ),
      vg.width(width),
      vg.height(500),
      vg.gridX(),
      vg.gridY(),
    )

    plotRef?.current.appendChild(plot)
    return () => {
      if (plotRef.current) {
        plotRef.current.removeChild(plot)
      }
    }
  }, [data, width])

  useEffect(() => {
    if (plotRef.current) {
      const resizeObserver = new ResizeObserver(() => {
        if (plotRef.current !== null && plotRef.current.offsetWidth !== width) {
          setWidth(plotRef.current.offsetWidth)
        }
      })
      resizeObserver.observe(plotRef.current)
      return () => {
        resizeObserver.disconnect()
      }
    }
  }, [plotRef.current])

  return <div ref={plotRef} className={styles.plot} />
}

export default Plot
