import { Button, Grid, TextField } from '@northvolt/ui'
import { useUpdateSample } from 'client/atlas/atlas'
import type { Attribute, Filter as LegacyFilter, Sample } from 'client/model'
import Filter from 'components/Filter'
import type { Filter as FilterType } from 'components/Filter/Types'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import GrainSelector from '../DataLoader/GrainSelector'

const EditSample = ({
  currentSample,
}: {
  currentSample: Sample | undefined
}) => {
  if (!currentSample) {
    return
  }

  const navigate = useNavigate()
  const [sample, setSample] = useState<Sample>(currentSample)
  const sampleMutation = useUpdateSample()

  const canSubmit = sample.name.length > 0 && sample.id && (sample.filters ?? []).length > 0

  useEffect(() => {
    if (sampleMutation.isSuccess) {
      navigate(`/atlas/samples/${sampleMutation.data.data.id}`)
    }
  }, [sampleMutation.status])

  useEffect(() => {
    if (sample.filters) {
      setSample({
        ...sample,
        filters: sample.filters,
      })
    }
  }, [sample.filters])

  return (
    <Grid container columns={12} spacing={2} sx={{ pt: 2 }}>
      <Grid xs={2}>
        <GrainSelector
          grain={sample.grain}
          setGrain={grain => setSample({ ...sample, grain: grain })}
          disabled={true}
        />
      </Grid>
      <Grid xs={6}>
        <TextField
          label='Name'
          size='small'
          autoComplete='off'
          value={sample.name}
          onChange={e => setSample({ ...sample, name: e.target.value })}
          fullWidth
        />
      </Grid>
      <Grid xs={8}>
        <TextField
          label='Description'
          size='small'
          autoComplete='off'
          value={sample.description}
          onChange={e => setSample({ ...sample, description: e.target.value })}
          fullWidth
        />
      </Grid>
      <Grid xs={12}>
        <Filter
          initial={(sample.filters ?? []) as FilterType<Attribute>[]}
          set={(filters: FilterType<Attribute>[]) => setSample({ ...sample, filters: filters as LegacyFilter[] })}
          grain={sample.grain}
        />
      </Grid>
      <Grid xs={12}>
        <Button
          variant='contained'
          color='primary'
          disabled={!canSubmit}
          onClick={() => {
            console.log('Update Sample', sample)
            sampleMutation.mutate({
              sampleId: sample.id,
              data: sample,
            })
          }}>
          Update Sample
        </Button>
      </Grid>
    </Grid>
  )
}

export default EditSample
