import { Divider, useTheme } from '@mui/material'
import { Autocomplete, Box, List, ListItem, Popper, TextField, Tooltip, Typography } from '@northvolt/ui'
import type { AttributeLight } from 'client/model'
import type { AttributeCollectionLight } from 'client/model/attributeCollectionLight'
import { fuzzyFilterOptions } from 'components/Utils'
import React, { useEffect } from 'react'
import type { SelectableAttribute, SelectableAttributeCollection } from '../DataLoader/DataLoaderTypes'
import AttributeChip from './AttributeChip'
import AttributeSelectItem from './AttributeSelectItem'

interface AttributeSelectorProps {
  label: string
  attributeCollections: SelectableAttributeCollection[] | AttributeCollectionLight[]
  selectedAttributes: SelectableAttribute[] | AttributeLight[]
  setSelectedAttributes: (attributes: SelectableAttribute[] | AttributeLight[]) => void
  dataTypeWhitelist?: string[]
  disabled?: boolean
  multiple?: boolean
  tooltip?: string
}

type OptionType = SelectableAttribute & { collectionName: string }

const AttributeSelector: React.FC<AttributeSelectorProps> = ({
  label,
  attributeCollections,
  selectedAttributes,
  setSelectedAttributes,
  dataTypeWhitelist,
  disabled,
  multiple,
  tooltip = 'Filter attributes by free text.',
}) => {
  const [options, setOptions] = React.useState<OptionType[]>([])
  const [groupBy, setGroupBy] = React.useState<boolean>(true)
  const theme = useTheme()

  useEffect(() => {
    if (!attributeCollections) {
      return
    }
    const optionsWithGroup: OptionType[] = attributeCollections.flatMap(collection => {
      if (!collection?.attributes) {
        return []
      } else {
        return collection.attributes
          .map(attr => ({
            ...attr,
            collectionName: collection.name,
            search_tags: [],
          }))
          .filter(attr => {
            if (dataTypeWhitelist) {
              return dataTypeWhitelist.includes(attr.data_type)
            }
            return true
          })
      }
    })

    setOptions(optionsWithGroup)
  }, [attributeCollections, dataTypeWhitelist])

  const CustomPopper = (props: any) => (
    <Popper
      {...props}
      style={{
        width: 'fit-content',
        minWidth: props.anchorEl?.clientWidth || 0,
        maxWidth: '90%',
      }}
    />
  )

  const getValue = multiple ? selectedAttributes : selectedAttributes.length > 0 ? selectedAttributes[0] : null

  return (
    <Tooltip title={tooltip} placement='top'>
      <Autocomplete
        size='small'
        options={options}
        filterOptions={fuzzyFilterOptions(option => option.unique_name)}
        value={getValue}
        onChange={(_: any, newValue: any) => {
          if (multiple) {
            setSelectedAttributes(newValue ? newValue : [])
          } else {
            setSelectedAttributes(newValue ? [newValue] : [])
          }
        }}
        groupBy={option => (groupBy ? option.collectionName : '')}
        onInputChange={(_event, newInputValue) => setGroupBy(newInputValue === '')}
        getOptionLabel={option => option.unique_name}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionDisabled={option => option.warning || false}
        renderOption={(props, option, state) => {
          const myProps: any = props // annoying...
          const { key, ...rest } = myProps
          return (
            <AttributeSelectItem
              attribute={option}
              listItemProps={rest}
              searchText={state.inputValue}
              key={option.unique_name}
            />
          )
        }}
        renderGroup={params => (
          <ListItem key={params.key} sx={{ p: 0, m: 0, flexDirection: 'column' }} disableGutters disablePadding>
            <Box
              sx={{
                position: 'sticky',
                top: '-10px',
                width: '100%',
                backgroundColor: theme.palette.background.default,
              }}>
              <Divider />
              <Typography>
                <strong>{params.group}</strong>
              </Typography>
              <Divider />
            </Box>
            <List disablePadding sx={{ width: '100%' }}>
              {params.children}
            </List>
          </ListItem>
        )}
        renderInput={params => <TextField {...params} label={label} variant='outlined' />}
        renderTags={(value: any) =>
          value.map((option: any, index: number) => <AttributeChip attribute={option} key={index} />)
        }
        PopperComponent={CustomPopper}
        autoHighlight
        autoComplete
        disabled={disabled}
        multiple={multiple}
        disableCloseOnSelect={multiple}
      />
    </Tooltip>
  )
}

export default AttributeSelector
