import { Button, Grid } from '@northvolt/ui'
import { useReadDataset } from 'client/atlas/atlas'
import type { DatasetLight } from 'client/model'
import React, { useEffect } from 'react'
import DatasetSearchAndSelect from '../Dataset/DatasetSearchAndSelect'
import type { DataLoadingParams } from './DataLoaderTypes'

import Filter from 'components/Filter'
import { parseAsBoolean, parseAsInteger, parseAsString, useQueryParam } from './../useQueryHook'
import RandomizeSwitch from './RandomizeSwitch'
import RowLimitSelector from './RowLimitSelector'
import StratificationControl from './StratificationControl'

type DatasetSelectorProps = {
  dataLoadingParams: DataLoadingParams
  setDataLoadingParams: (dataLoadingParams: DataLoadingParams) => void
  disabled?: boolean
}

const DatasetSelector: React.FC<DatasetSelectorProps> = ({ dataLoadingParams, setDataLoadingParams, disabled }) => {
  const [selectedDataset, setSelectedDataset] = React.useState<DatasetLight>()
  const [selectedDatasetId, setSelectedDatasetId] = useQueryParam('datasetId', undefined, parseAsInteger)
  const [rowLimit, setRowLimit] = useQueryParam('rowLimit', 1000, parseAsInteger)
  const [randomize, setRandomize] = useQueryParam('randomize', false, parseAsBoolean)

  const [stratificationColUniqueName, setStratificationColUniqueName] = useQueryParam(
    'stratificationColUniqueName',
    undefined,
    parseAsString,
  )

  const datasetLoader = useReadDataset(selectedDatasetId)

  const [selectedParams, setSelectedParams] = React.useState<DataLoadingParams>({
    ...dataLoadingParams,
    row_limit: rowLimit,
    randomize: randomize,
    filters: [],
    stratification_col_unique_name: stratificationColUniqueName,
  })

  useEffect(() => {
    const dataset = datasetLoader.data?.data
    if (datasetLoader.isSuccess && dataset?.id !== selectedDataset?.id) {
      setSelectedDataset(dataset)
      setSelectedParams({
        ...selectedParams,
        ...dataset,
      })
    }
  }, [datasetLoader.data?.data])

  useEffect(() => {
    setSelectedParams({
      ...selectedParams,
      row_limit: rowLimit,
    })
  }, [rowLimit])

  useEffect(() => {
    setSelectedParams({
      ...selectedParams,
      randomize: randomize,
    })
  }, [randomize])

  useEffect(() => {
    setSelectedParams({
      ...selectedParams,
      stratification_col_unique_name: stratificationColUniqueName,
    })
  }, [stratificationColUniqueName])

  const canSubmit = () => {
    return selectedParams.attribute_collections?.length && selectedParams.samples?.length && selectedParams.row_limit
  }

  const submit = () => {
    setDataLoadingParams(selectedParams)
  }

  return (
    <Grid container spacing={{ xs: 1 }} columns={{ xs: 6, lg: 12 }} sx={{ mt: 1 }}>
      <Grid xs={6}>
        <DatasetSearchAndSelect
          selectedDatasets={selectedDataset ? [selectedDataset] : []}
          setSelectedDatasets={datasets => {
            setSelectedDataset(datasets[0])
            setSelectedParams({
              ...selectedParams,
              ...datasets[0],
            })
            setSelectedDatasetId(datasets[0]?.id)
          }}
          disabled={disabled}
          multiple={false}
        />
      </Grid>
      <Grid xs={4}>
        <RowLimitSelector rowLimit={rowLimit} setRowLimit={setRowLimit} />
      </Grid>
      <Grid xs={2}>
        <Grid xs={2}>
          <RandomizeSwitch randomize={randomize} setRandomize={setRandomize} />
        </Grid>
      </Grid>
      <Grid xs={5}>
        <StratificationControl
          attributeCollections={selectedParams.attribute_collections ? selectedParams.attribute_collections : []}
          stratificationAttributeName={stratificationColUniqueName}
          setStratificationAttributeName={attributeName => setStratificationColUniqueName(attributeName)}
        />
      </Grid>

      <Grid xs={12}>
        <Filter
          elements={
            selectedParams.attribute_collections
              ? selectedParams.attribute_collections.flatMap(({ attributes }) => attributes)
              : []
          }
          set={filters => setSelectedParams({ ...selectedParams, filters: filters })}
          disabled={disabled}
          grain={selectedParams.grain}
        />
      </Grid>
      <Grid xs={2}>
        <Button variant='contained' size='medium' disabled={!canSubmit()} onClick={submit}>
          Load
        </Button>
      </Grid>
    </Grid>
  )
}

export default DatasetSelector
