import { FormControl } from '@mui/material'
import { Switch, Tooltip } from '@northvolt/ui'
import type React from 'react'

interface RandomizeSwitchProps {
  randomize: boolean
  setRandomize: (randomize: boolean) => void
}

const RandomizeSwitch: React.FC<RandomizeSwitchProps> = ({ randomize, setRandomize }) => {
  return (
    <Tooltip
      placement='top'
      title={'Randomly sample data. Default behaviour loads the last N cells created in Cell Assembly.'}>
      <FormControl fullWidth>
        <Switch label='Randomize' checked={randomize} onChange={(_, checked) => setRandomize(checked)} />
      </FormControl>
    </Tooltip>
  )
}

export default RandomizeSwitch
