import { Chip } from '@northvolt/ui'
import type { SearchTag } from 'client/model'
import getSearchTagIcon from './SearchTagIcon'

interface SearchTagChipProps {
  searchTag: SearchTag
  clickHandler?: (searchTag: SearchTag) => void
  chipProps?: any
}

export function searchForTag(searchTag: SearchTag) {
  const url = new URL('/atlas/search', window.location.origin)
  url.searchParams.set('tagIds', searchTag.id.toString())
  window.location.href = url.toString()
}

const SearchTagChip: React.FC<SearchTagChipProps> = ({ searchTag, clickHandler, chipProps }) => {
  function handleClick() {
    if (clickHandler !== undefined) {
      clickHandler(searchTag)
    }
  }

  const { key, ...props } = chipProps ?? {}

  return (
    <Chip
      title={`${searchTag.key} = ${searchTag.value}`}
      label={`${searchTag.key} = ${searchTag.value}`}
      size='small'
      sx={{ m: 0.25, maxWidth: 220 }}
      color='secondary'
      icon={getSearchTagIcon(searchTag.key)}
      onClick={handleClick}
      clickable={clickHandler !== undefined}
      variant='outlined'
      {...props}
    />
  )
}

export default SearchTagChip
