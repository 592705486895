import * as vg from '@uwdata/vgplot'
import { Particles, Right, Target } from 'assets/icons'
import { createChangeDetectorId, useChangeDetector } from 'contexts/APIContext'
import { useForm } from 'contexts/FormContext'
import { type FormEventHandler, type MouseEventHandler, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import Plot from './ChangeDetectionResultPlot'
import { CellType, ProcessType } from './Types'
import styles from './change-detector.module.scss'

const date = new Date()
const today = date.toISOString().split('T')[0]
date.setDate(date.getDate() - 14)
const fortnite = date.toISOString().split('T')[0]

export default () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const id = searchParams.get('requestId') ?? undefined
  const { data, execute, loading, success } = useChangeDetector(id || '')
  const [selected, select] = useState<any>()
  const { form, dispatchValue } = useForm({
    process: {
      label: 'Process',
      value: '',
      pristine: true,
      type: 'select',
    },
    cell: {
      label: 'Cell Kind',
      value: '',
      pristine: true,
      type: 'select',
    },
    start: {
      label: 'Start Date',
      value: fortnite,
      pristine: true,
      type: 'date',
    },
    end: {
      label: 'End date',
      value: today,
      pristine: true,
      type: 'date',
    },
  })

  const createId = createChangeDetectorId()

  useEffect(() => {
    if (data) {
      const df = data?.dataframe
      if (Array.isArray(df) && df.length > 0) {
        const duckdb = vg.wasmConnector()
        vg.coordinator().databaseConnector(duckdb)
        vg.coordinator()
          .exec([vg.loadExtension('icu')])
          .then(() => {
            duckdb
              .getDuckDB()
              .then((db: any) => {
                db.registerFileText('rows.json', JSON.stringify(df))
              })
              .then(() => {
                duckdb.getConnection().then((con: any) => {
                  con.insertJSONFromPath('rows.json', {
                    name: `change_detector_${data.id}`,
                  })
                })
              })
          })
      }
    }
  }, [data, id, selected])

  const handleSubmit: FormEventHandler<HTMLFormElement> & MouseEventHandler<HTMLButtonElement> = async e => {
    e.preventDefault()
    try {
      const result = await createId(
        new Date(form.start.value).toISOString(),
        new Date(form.end.value).toISOString(),
        form.process.value,
        form.cell.value,
      )
      setSearchParams({ requestId: `${result}` })
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    const newId = searchParams.get('requestId')
    if (newId) execute()
  }, [searchParams])

  useEffect(() => {
    if (success) {
      select(data.results[0])
    }
  }, [success])

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles[`title${success ? '_small' : ''}`]}>
          <h1>
            <Target />
            Change Detector
          </h1>
        </div>
        {success && (
          <div className={styles.info}>
            <span>
              <b>{data.process}</b>
            </span>
            <span>{data.cell_kind}</span>
            <span>
              {data.start_date.split('T')[0]} - {data.end_date.split('T')[0]}
            </span>
          </div>
        )}
        {success && selected && (
          <div className={styles.area}>
            <div className={styles.scrollableList}>
              <div className={styles.attrList}>
                {data.results.map((result: any) => {
                  const name = result.attribute_name
                  return (
                    <button
                      key={name}
                      className={name === selected.attribute_name ? styles.selected : ''}
                      onClick={() => select(result)}>
                      {name}
                      <p>
                        {result.outliers.length > 0 && (
                          <>
                            <span>
                              <b>{result.outliers.length}</b>
                            </span>
                            {` outlier${result.outliers.length > 1 ? 's' : ''}`}
                          </>
                        )}
                        {result.change_points.length > 0 && (
                          <>
                            <span>{result.change_points.length}</span>
                            {` change
                            point ${result.change_points.length > 1 ? 's' : ''}`}
                          </>
                        )}
                      </p>
                    </button>
                  )
                })}
              </div>
            </div>
            <div className={styles.graphArea}>
              <Plot data={selected} tableName={`change_detector_${id}`} />
            </div>
          </div>
        )}

        <div className={styles.formArea}>
          <Particles isLoading={loading} className={loading ? '' : styles.pulse} />
          <form onSubmit={handleSubmit} className={styles[loading ? 'hidden' : 'form']}>
            <span>Detect changes between</span>
            <input
              placeholder={fortnite}
              type='date'
              max={form.end?.value}
              value={form.start?.value}
              onChange={({ target }) => dispatchValue('start', target.value)}
            />
            <span>and</span>
            <input
              placeholder={today}
              type='date'
              min={form.start?.value}
              value={form.end?.value}
              onChange={({ target }) => dispatchValue('end', target.value)}
            />
            <span>in the</span>
            <input
              list='process'
              placeholder={ProcessType.FINAL_INSPECTION}
              value={form.process?.value}
              onChange={({ target }) => dispatchValue('process', target.value)}
            />
            <datalist id='process' className={styles.datalist}>
              {Object.values(ProcessType).map((process: string) => (
                <option key={process} value={process} />
              ))}
            </datalist>
            <span>process measurements from</span>
            <input
              className={styles.cell}
              list='cell'
              placeholder={CellType.PPE_CV}
              value={form.cell?.value}
              onChange={({ target }) => dispatchValue('cell', target.value)}
            />
            <datalist id='cell' className={styles.datalist}>
              {Object.values(CellType).map((cell: string) => (
                <option key={cell} value={cell} />
              ))}
            </datalist>
            <span>cells.</span>
            <button
              type='submit'
              title='send'
              onClick={handleSubmit}
              disabled={!(form.process?.value && form.cell?.value && form.start?.value && form.end?.value)}
              className={success || loading ? styles.sendButton : styles.fixedSendButton}>
              <Right />
            </button>
          </form>
        </div>
      </div>
    </>
  )
}
