import { Box } from '@mui/material'
import { Button, Card, CardActions, CardContent, CardHeader, Typography } from '@northvolt/ui'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'

type MenuCardProps = {
  title: string
  category: string
  description: string
  link: string
  icon?: React.ReactNode
  delay: number
}

const MenuCard: React.FC<MenuCardProps> = ({ title, category, description, link, icon, delay }) => {
  return (
    <motion.div
      initial={{ opacity: 0, translateX: -25 }}
      animate={{ translateX: 0, opacity: 1, from: 0, to: 1 }}
      transition={{ delay: delay || 0.1, ease: 'easeIn' }}>
      <Card sx={{ height: 260, p: 2, display: 'flex', flexDirection: 'column' }}>
        <Link to={link} style={{ textDecoration: 'none', color: 'inherit' }}>
          <CardHeader title={title} sx={{ pb: 0 }} />
        </Link>
        <CardContent>
          <Typography sx={{ mb: 1.5 }} color='text.secondary'>
            {category}
          </Typography>
          <Typography
            variant='bodyMedium'
            sx={{
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 3, // Change this to the number of lines you want to display
              overflow: 'hidden',
            }}>
            {description}
          </Typography>
        </CardContent>
        <Box sx={{ flexGrow: 1 }} />
        <CardActions>
          <Link to={link}>
            <Button size='medium' endIcon={icon}>
              Open
            </Button>
          </Link>
        </CardActions>
      </Card>
    </motion.div>
  )
}

export default MenuCard
