import { getIdToken } from '@northvolt/snowflake'
import type { AssistantEnum } from 'client/model'
import { RemoteRunnable } from 'langchain/runnables/remote'
import { useCallback, useEffect, useMemo, useState } from 'react'

export interface GigaWattsonHook {
  prompt?: string
  isLoading: boolean
  isError: boolean
  isSuccessful: boolean
  response: string
  error: Error | null
}

export function useGigaWattson(assistant: AssistantEnum, prompt?: string): GigaWattsonHook {
  const [response, setResponse] = useState<string>('')
  const [error, setError] = useState<Error | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isSuccessful, setIsSuccessful] = useState<boolean>(false)

  const remoteChain = useMemo(
    () =>
      new RemoteRunnable({
        url: `${import.meta.env.VITE_API_URI}api/chat/${assistant ?? 'problemsolver'}`,
        options: {
          timeout: 5 * 60 * 1000,
          headers: {
            Authorization: `Bearer ${getIdToken()}`,
          },
        },
      }),
    [],
  )

  const fetchData = useCallback(async () => {
    setIsLoading(true)
    setIsSuccessful(false)
    try {
      const result = await remoteChain
        .invoke(
          {
            input: prompt ?? '',
            chat_history: '',
          },
          {
            configurable: {},
          },
        )
        .then((response: any) => response.output)
      setResponse(result)
      setIsSuccessful(true)
    } catch (err) {
      setError(err as Error)
    } finally {
      setIsLoading(false)
    }
  }, [prompt, remoteChain])

  useEffect(() => {
    if (prompt !== undefined) {
      fetchData()
    }
  }, [prompt, fetchData])

  return {
    prompt,
    isLoading,
    isError: error !== null,
    isSuccessful,
    response,
    error,
  }
}
