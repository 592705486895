'use client'
import { CancelOutlined, CheckCircle, ErrorOutlineOutlined, ExpandMore } from '@mui/icons-material'
import { Accordion, AccordionDetails, AccordionSummary, Button } from '@mui/material'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import {
  useGetCorrelationFinderRequest,
  useGetCorrelationFinderResults,
} from 'client/correlation-finder/correlation-finder'
import {
  type Attribute,
  type CorrelationFinderResults,
  type DataLoadingParamsLight,
  ExecutionStatus,
} from 'client/model'
import CorrelationFinderForm from './CorrelationFinderForm'
import type { PageParams } from './Types'
import styles from './correlation-finder.module.scss'

const BlobImageDisplay = ({ blobString }: { blobString: string }) => {
  const [imageUrl, setImageUrl] = useState('')

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await fetch(blobString)
        const blob = await response.blob()
        const url = URL.createObjectURL(blob)
        setImageUrl(url)
      } catch (error: any) {
        console.error('Error fetching image:', error.message)
      }
    }

    fetchImage()

    return () => {
      if (imageUrl) {
        URL.revokeObjectURL(imageUrl)
      }
    }
  }, [blobString])

  if (imageUrl)
    return (
      <div>
        <img src={imageUrl} alt='Chart' className={styles.img} />
      </div>
    )
}

export default () => {
  const { id } = useParams()
  const [params, setParams] = useState<PageParams | undefined>()
  const [copied, setCopied] = useState<boolean>(false)
  const [errorBody, setErrorBody] = useState<JSX.Element>()
  const [modal, setModalImage] = useState('')
  const [formPayload, setFormPayload] = useState<{
    data_loading_params?: DataLoadingParamsLight
    target_attribute?: Attribute
    sample_mode?: boolean
  }>()
  const navigate = useNavigate()

  const correlationId = Number(id)

  const { data: requestData, error: requestError } = useGetCorrelationFinderRequest(correlationId, {
    query: {
      enabled: !!correlationId,
      refetchIntervalInBackground: true,
      refetchInterval: (data, query) => {
        if (query.state.status !== 'error' && data?.data?.status === ExecutionStatus.RUNNING) {
          return 8000
        }
        return false
      },
      retry: (failureCount: number, error: any) => {
        if (error.status === 404) {
          return false
        }
        if (failureCount > 3) {
          return false
        }
        return true
      },
    },
  })

  const { data: resultsData, refetch: refetchResults } = useGetCorrelationFinderResults(correlationId, {
    query: {
      enabled: false,
    },
  })

  useEffect(() => {
    if (!requestData || formPayload) return
    const { status, data_loading_params, target_attribute, sample_mode } = requestData.data

    if (
      target_attribute?.unique_name &&
      !!data_loading_params?.samples.length &&
      !!data_loading_params?.attribute_collections.length
    ) {
      setParams({
        targetName: target_attribute?.unique_name,
        sampleNames: data_loading_params?.samples.map(({ name }) => name),
        attributeCollectionNames: data_loading_params?.attribute_collections.map(({ name }) => name),
      })
    }
    setFormPayload({
      data_loading_params: data_loading_params as DataLoadingParamsLight,
      target_attribute: target_attribute as Attribute,
      sample_mode,
    })
    if (status === 'SUCCESS') {
      refetchResults()
    }
  }, [requestData?.data, refetchResults])

  const copyUrl = () => {
    navigator.clipboard.writeText(window.location.href)
    setCopied(true)
    setTimeout(() => setCopied(false), 2000)
  }

  useEffect(() => {
    if (requestData?.data) {
      const { status, error } = requestData?.data || {}
      if (status === 'SUCCESS' && correlationId) {
        refetchResults()
      }
      if (status === 'FAILURE') {
        setErrorBody(
          <p className={styles.msg}>
            Ops
            <br />
            It's not you, it's us. {error && 'It says:'}
            <br />
            <b>{error && `"${error}"`}</b>
            Please try again, or reach the Data and AI team for support -<br /> we are here to help.
            <Button onClick={() => navigate('../../tools/correlation-finder')}>{'Wanna try again?'}</Button>
          </p>,
        )
      }
    }
  }, [requestData?.data])

  useEffect(() => {
    if (requestError) {
      setErrorBody(
        <p className={styles.msg}>
          Ops
          <br />
          It's not you, it's us. {requestError?.response?.data.detail && 'It says:'}
          <br />
          <b>{requestError?.response?.data.detail && `"${requestError?.response?.data.detail}"`}</b>
          Please try again, or reach the Data and AI team for support -<br /> we are here to help.
          <Button onClick={() => navigate('../../')}>{'Wanna try again?'}</Button>
        </p>,
      )
    }
  }, [requestError?.response])

  useEffect(() => {
    if (!id) {
      setErrorBody(
        <p className={styles.errorMsg}>
          You forgot the identifier number we use to check the correlation results.
          <br />
          It's ok, we all forget things sometimes.
        </p>,
      )
      return
    }

    if (Number.isNaN(Number(id))) {
      setErrorBody(
        <p className={styles.errorMsg}>
          There's a letter in your identifier number.
          <br />
          That's ... not a number.
        </p>,
      )
      return
    }
    let local: string | null = null
    if (!params) {
      local = localStorage.getItem('__correlation-finder')
    }
    if (!local) return
    const state = JSON.parse(local)[id]
    setParams(state || {})
  }, [id])

  if (resultsData?.data) {
    const {
      outcome_description: outcome,
      top_attributes: attrs,
      disclaimer_text: disclaimer,
      finished_at: end,
      outcome_traffic_light: quality,
      problem_type: problem,
      started_at: start,
      test_metrics: testMetrics,
    }: CorrelationFinderResults = resultsData?.data || {}

    const { beeswarm_plot, cm_plot, error_plot, regression_plot, roc_plot, roc_auc } = testMetrics || {}

    const accuracy = roc_auc ? roc_auc * 100 : 0

    const plots = [beeswarm_plot, cm_plot, roc_plot, error_plot, regression_plot]
    const importanceTotalSum = attrs?.reduce((acc, { relative_importance }) => acc + relative_importance, 0) as number
    const importanceText =
      importanceTotalSum > 0.8
        ? ' appear to be more important '
        : importanceTotalSum > 0.6
          ? 'appear to be somewhat more important '
          : ' do not appear to be that much more important '

    let trust = 'is unknown'
    let Icon = () => <CheckCircle color='disabled' />

    switch (quality) {
      case 'red':
        trust = 'not very promising'
        Icon = () => <CancelOutlined color='error' />
        break
      case 'yellow':
        trust = 'fairly promising'
        Icon = () => <ErrorOutlineOutlined color='warning' />
        break
      case 'green':
        trust = 'promising'
        Icon = () => <CheckCircle color='success' />
        break
      default:
        break
    }
    const topAttr = attrs?.[0]

    return (
      resultsData?.data.status === 'SUCCESS' && (
        <>
          <div className={styles.accordion_form}>
            <Accordion expanded={true}>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <b className={styles.accordion_summary}>Correlation Finder</b>
              </AccordionSummary>
              <AccordionDetails>
                <CorrelationFinderForm {...formPayload} />
              </AccordionDetails>
            </Accordion>
          </div>
          <div className={styles.container}>
            <Icon />
            <h2>The final results are {trust}</h2>
            <div className={styles.shift}>
              <p>{outcome}</p>
              <div className={styles.row}>
                <div className={styles.short_column}>
                  <h1>{accuracy?.toFixed(2)}%</h1>
                  Algorithm Accuracy
                </div>
                <div className={styles.short_column}>
                  <p>
                    Problem type: <b>{problem}</b>
                  </p>
                  <p>
                    started at:{' '}
                    <b>
                      {new Date(start).toLocaleString('sv', {
                        timeZone: 'Europe/Stockholm',
                      })}
                    </b>
                  </p>
                  <p>
                    finished at:{' '}
                    <b>
                      {new Date(end).toLocaleString('sv', {
                        timeZone: 'Europe/Stockholm',
                      })}
                    </b>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.accordionContainer}>
            <Accordion disableGutters defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <b className={styles.bolder}>Findings</b>
              </AccordionSummary>
              <AccordionDetails>
                <div className={styles.accorcontainer}>
                  {params?.targetName && (
                    <div>
                      <div className={styles.formContainerReverse}>
                        <p>
                          <b className={styles.bold}>Target attribute</b>
                        </p>
                        <p>
                          <b className={styles.bold}>Referential parameters</b>
                        </p>
                      </div>
                      <div className={styles.formContainerReverse}>
                        <p>{params.targetName}</p>

                        <p>{[...params.sampleNames, params.attributeCollectionNames].join(', ')}</p>
                      </div>
                    </div>
                  )}

                  <b className={styles.bold}>Top Attributes</b>
                  <p>
                    The most impactful attribute for predicting <b>{params?.targetName}</b> was{' '}
                    <b>{topAttr?.attribute_unique_name}</b>, which means this is the attribute that influenced the
                    algorithm the most. You might want to check the relationship out both below and in the Plotter tool.
                    Also, the total relative importance of these top attributes is{' '}
                    <b>{(importanceTotalSum * 100).toFixed(1)}%</b>, which means they
                    <b>{importanceText}</b> than the rest of the attributes investigated.
                  </p>
                  <div className={styles.container__table}>
                    <div className={styles.attr}>
                      <span className={styles.attr1}>
                        <b>Name</b>
                      </span>
                      <span className={styles.attr2}>
                        <b>Impact</b>
                      </span>
                      <span className={styles.attr3}>
                        <b>Relative Importance</b>
                      </span>
                    </div>
                    {attrs?.map((attr, i) => {
                      const { attribute_unique_name: name, impact, relative_importance: importance } = attr
                      return (
                        <div key={`${name}_${i}`} className={styles.attr}>
                          <span className={styles.attr1}>{name}</span>
                          <span className={styles.attr2}>{impact.toFixed(3)}</span>
                          <span className={styles.attr3}>{(importance * 100).toFixed(3)}%</span>
                        </div>
                      )
                    })}
                  </div>
                </div>
                <div className={styles.shift}>
                  {plots.map(
                    plot =>
                      plot && (
                        <div key={plot} className={styles.modalSign} onClick={() => setModalImage(plot)}>
                          <BlobImageDisplay blobString={`data:image/png;base64,${plot}`} />
                        </div>
                      ),
                  )}
                </div>
                <h4>Disclaimer</h4>
                <p>{disclaimer}</p>
              </AccordionDetails>
            </Accordion>
            {resultsData?.data.top_attributes?.map(attr => {
              const { attribute_unique_name: name, plot } = attr
              return (
                name && (
                  <Accordion key={`${name}_${plot}`}>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                      <b className={styles.accordion_summary}>{name}</b>
                    </AccordionSummary>
                    <AccordionDetails>
                      <BlobImageDisplay blobString={`data:image/png;base64,${plot}`} />
                    </AccordionDetails>
                  </Accordion>
                )
              )
            })}
          </div>
          {!!modal && (
            <div onClick={() => setModalImage('')} className={styles.modalBg}>
              <BlobImageDisplay blobString={`data:image/png;base64,${modal}`} />
            </div>
          )}
        </>
      )
    )
  }

  return (
    <>
      <div className={styles.accordion_form}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <b className={styles.accordion_summary}>Correlation Finder</b>
          </AccordionSummary>
          <AccordionDetails>
            <CorrelationFinderForm {...formPayload} />
          </AccordionDetails>
        </Accordion>
      </div>
      {errorBody ? (
        <div className={styles.container}>
          <CancelOutlined color='error' />
          <h1>Error</h1>
          <p className={styles.errorMsg}>{errorBody}</p>
        </div>
      ) : (
        <div className={styles.container}>
          <div className={styles.loading} />
          <h1>Processing Your Report</h1>
          <p className={styles.msg}>
            We are processing your request.
            <br />
            This may take a few minutes, go get yourself a cup of coffee. <br />
            Or four cups, this might take a while.
          </p>
          <p className={styles.msg}>
            By the way, you can close this window -<br />
            just be sure to copy the URL and check later.
          </p>
          <Button onClick={copyUrl}>{copied ? 'Copied!' : 'Copy Page URL'}</Button>
        </div>
      )}
    </>
  )
}
