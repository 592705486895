import type { FilterOptionsState } from '@mui/material'
import { Autocomplete, CircularProgress, TextField, Tooltip } from '@northvolt/ui'
import { useDebounce } from '@uidotdev/usehooks'
import { useSearchAttributeCollectionsByText } from 'client/atlas/atlas'
import type { AttributeCollection, AttributeCollectionLight, MaterialType } from 'client/model'
import React, { useEffect } from 'react'
import AttributeCollectionChip from './AttributeCollectionChip'
import AttributeCollectionSelectItem from './AttributeCollectionSelectItem'

interface AttributeCollectionSearchAndSelectProps {
  label: string
  placeholder?: string
  suggestedAttributeCollections?: AttributeCollectionLight[]
  selectedAttributeCollections: (AttributeCollectionLight | number)[]
  setSelectedAttributeCollections: (attributeCollections: AttributeCollectionLight[]) => void
  grain: MaterialType
  disabled?: boolean
  multiple?: boolean
  onlyUserCollections?: boolean
}

const AttributeCollectionSearchAndSelect: React.FC<AttributeCollectionSearchAndSelectProps> = ({
  label,
  placeholder,
  suggestedAttributeCollections,
  selectedAttributeCollections,
  setSelectedAttributeCollections,
  grain,
  disabled,
  multiple,
  onlyUserCollections = false,
}) => {
  const [searchText, setSearchText] = React.useState<string>('')
  const [showPlaceholder, setshowPlaceholder] = React.useState<boolean>(true)
  const debouncedSearchText = useDebounce(searchText, 300)
  const attributeCollectionLoader = useSearchAttributeCollectionsByText()

  useEffect(() => {
    if (debouncedSearchText) {
      attributeCollectionLoader.mutate({
        params: {
          text: debouncedSearchText,
          only_user_collections: onlyUserCollections,
          grain: grain,
        },
      })
    } else {
      if (onlyUserCollections) {
        attributeCollectionLoader.mutate({
          params: {
            text: '',
            only_user_collections: onlyUserCollections,
            grain: grain,
          },
        })
      } else {
        attributeCollectionLoader.reset()
      }
    }
  }, [debouncedSearchText])

  const getOptions = () => [
    ...new Set([
      ...(attributeCollectionLoader.data?.data?.items ?? suggestedAttributeCollections ?? []),
      ...(selectedAttributeCollections ?? []),
    ]),
  ]

  const handleChange = (_: any, newValue: any) => {
    if (multiple) {
      setSelectedAttributeCollections(newValue)
    } else {
      setSelectedAttributeCollections(newValue ? [newValue] : [])
    }
  }

  const handleInputChange = (_event: React.SyntheticEvent, newInputValue: string) => {
    setSearchText(newInputValue)
  }

  useEffect(() => {
    if (selectedAttributeCollections.length === 0) {
      setshowPlaceholder(true)
      attributeCollectionLoader.reset()
    } else {
      setshowPlaceholder(false)
    }
  }, [selectedAttributeCollections])

  return (
    <Tooltip placement='top' title={'Free-text search for attribute collections.'}>
      <Autocomplete
        size='small'
        options={getOptions() as any}
        filterOptions={(options: AttributeCollection[], _: FilterOptionsState<any>) => options} // Don't do any additional filtering in the frontend
        loading={attributeCollectionLoader.isLoading}
        value={
          (multiple
            ? selectedAttributeCollections
            : selectedAttributeCollections?.length > 0
              ? selectedAttributeCollections[0]
              : null) as any
        }
        onInputChange={handleInputChange}
        onChange={(_: any, newValue: any) => handleChange(_, newValue)}
        getOptionLabel={(attributeCollection: any) => attributeCollection.name}
        isOptionEqualToValue={(attributeCollection: any, other: any) => attributeCollection.id === other.id}
        renderOption={(props, attributeCollection: AttributeCollectionLight) => {
          const myProps: any = props // annoying...
          const { key, ...rest } = myProps
          return (
            <AttributeCollectionSelectItem
              attributeCollection={attributeCollection}
              listItemProps={rest}
              searchText={searchText}
              key={attributeCollection.id}
            />
          )
        }}
        renderInput={params => (
          <TextField
            {...params}
            label={label}
            placeholder={showPlaceholder ? placeholder || '' : ''}
            variant='outlined'
            InputLabelProps={{ shrink: placeholder ? true : undefined }}
            InputProps={{
              ...params.InputProps,
              sx: {
                '&::placeholder': {
                  color: 'green',
                },
              },
              endAdornment: (
                <>
                  {attributeCollectionLoader.isLoading ? <CircularProgress color='inherit' size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        renderTags={(value: any, getTagProps: any) =>
          value.map((attributeCollection: AttributeCollection, index: number) => (
            <AttributeCollectionChip
              attributeCollection={attributeCollection}
              key={index}
              chipProps={getTagProps({ index })}
            />
          ))
        }
        autoHighlight
        autoComplete
        fullWidth
        filterSelectedOptions
        noOptionsText='Empty or invalid search query (please type something).'
        disabled={disabled}
        multiple={!!multiple}
      />
    </Tooltip>
  )
}

export default AttributeCollectionSearchAndSelect
