import { getIdToken } from '@northvolt/snowflake'
import type { Grain } from 'components/Filter/Types'
import type React from 'react'
import { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react'
import type { CellType, ProcessType } from 'routes/ChangeDetector/Types'
import type { ApiContextType, ApiState } from './Types'

const ApiContext = createContext<ApiContextType | undefined>(undefined)

const ApiProvider = ({ children }: { children: React.ReactNode }) => {
  const [token, setToken] = useState<string | null>(null)

  const execute = async <T = any, P = any>(
    endpoint: string,
    params?: P,
    options: RequestInit = {},
    retryAttempts = 2,
  ): Promise<T> => {
    const apiUri = import.meta.env.VITE_API_URI
    if (!apiUri) {
      throw new Error('API URI is not defined in the environment variables')
    }

    const url = new URL(`${apiUri}${endpoint}`)
    if (params && (!options.method || options.method === 'GET' || !options.body)) {
      Object.entries(params).map(([key, value]) => {
        if (value !== undefined && value !== null) {
          url.searchParams.append(key, String(value))
        }
      })
    }

    let lastError: Error | null = null
    for (let attempt = 0; attempt < retryAttempts; attempt++) {
      try {
        if (!token) {
          const newToken = await getIdToken()
          setToken(newToken)
        }

        const response = await fetch(url.toString(), {
          ...options,
          headers: {
            'Authorization': `Bearer ${getIdToken()}`,
            'Content-Type': 'application/json',
            ...options.headers,
          },
        })

        if (!response.ok) {
          throw new Error(`${response.status} - ${response.statusText}`)
        }

        const data = await response.json()
        return data
      } catch (error) {
        lastError = error instanceof Error ? error : new Error('Unknown error')
        if (attempt === retryAttempts - 1) throw lastError
        await new Promise(resolve => setTimeout(resolve, 1000 * (attempt + 1)))
      }
    }

    throw lastError
  }

  return <ApiContext.Provider value={{ execute }}>{children}</ApiContext.Provider>
}

const useApi = (endpoint: string, defaultOptions: RequestInit = { method: 'GET' }, retry?: number) => {
  const context = useContext(ApiContext)
  if (!context) {
    throw new Error('useApi must be used within ApiProvider')
  }

  const [state, setState] = useState<ApiState<any>>({
    data: null,
    error: null,
    success: false,
  })

  const execute = useCallback(
    async (params?: any, options: RequestInit = {}, retryAttempts?: number) => {
      const retries = retryAttempts ?? retry ?? 2
      setState(prev => ({
        ...prev,
        error: null,
        success: false,
      }))
      try {
        const result = await context.execute(
          endpoint,
          params,
          {
            ...defaultOptions,
            ...options,
          },
          retries,
        )
        if (result != state.data) {
          setState({
            data: result,
            error: null,
            success: true,
          })
        }
        return result
      } catch (error) {
        const errorMessage = error instanceof Error ? error.message : 'Unknown error'
        setState({
          data: null,
          error: new Error(`${errorMessage}`),
          success: false,
        })
        throw error
      }
    },
    [context, endpoint, defaultOptions],
  )

  return {
    ...state,
    execute,
  }
}

// Custom hook to perform a text search using the provided value and grain
const useSearch = (value: string, grain: Grain) =>
  useApi(`api/atlas/attributes/text_search?text=${value}&grain=${grain}&limit=25&offset=0`, { method: 'POST' })

// Custom hook to create a new analysis of sudden changes detected.
const createChangeDetectorId = () => {
  const { execute } = useApi('api/change_detector/', {
    method: 'POST',
  })

  return async (start: string, end: string, process: ProcessType, cell: CellType): Promise<number> =>
    execute(null, {
      body: JSON.stringify({
        start_date: start,
        end_date: end,
        process: process,
        cell_kind: cell,
      }),
    })
}

// Custom hook to fetch the change detector result by id
const useChangeDetector = (id: string) => {
  const { data, execute: originalExecute, error, success } = useApi(`api/change_detector/${id}`, { method: 'GET' })

  const [polling, setPolling] = useState(false)
  const pollingIntervalRef = useRef<NodeJS.Timeout | null>(null)

  const execute = async () => {
    try {
      setPolling(true)
      const response = await originalExecute()

      if (pollingIntervalRef.current) {
        clearInterval(pollingIntervalRef.current)
      }

      if (response?.status === 'RUNNING') {
        pollingIntervalRef.current = setInterval(async () => {
          const pollResponse = await originalExecute()

          if (pollResponse?.status !== 'RUNNING') {
            pollingIntervalRef.current && clearInterval(pollingIntervalRef.current)
            setPolling(false)
          }
        }, 5000)
      } else {
        setPolling(false)
      }
    } catch (err) {
      console.error('Error in execute:', err)
      if (pollingIntervalRef.current) {
        clearInterval(pollingIntervalRef.current)
      }
      setPolling(false)
    }
  }

  useEffect(() => {
    return () => {
      if (pollingIntervalRef.current) {
        clearInterval(pollingIntervalRef.current)
        setPolling(false)
      }
    }
  }, [id])

  return { data, execute, loading: polling, error, success }
}

export { ApiProvider, createChangeDetectorId, useApi, useChangeDetector, useSearch }
