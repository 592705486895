import * as Sentry from '@sentry/react'

import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom'

import ErrorRoute from 'routes/404'
import HelpRoute from 'routes/Help'
import LandingRoute from 'routes/Landing'
import RootRoute from 'routes/Root'

import DatasetEditRouter from 'routes/Atlas/datasets/edit'
import DatasetIdRouter from 'routes/Atlas/datasets/id'
import DatasetNewRouter from 'routes/Atlas/datasets/new'

import MyDataRouter from 'routes/Atlas/my-data'
import SearchRoute from 'routes/Atlas/search'

import AttributesRoute from 'routes/Atlas/attributes'

import SampleEditRouter from 'routes/Atlas/samples/edit'
import SampleIdRouter from 'routes/Atlas/samples/id'
import SampleNewRouter from 'routes/Atlas/samples/new'

import AttributeCollectionEditRouter from 'routes/Atlas/attribute-collections/edit'
import AttributeCollectionIdRouter from 'routes/Atlas/attribute-collections/id'
import AttributeCollectionNewRouter from 'routes/Atlas/attribute-collections/new'

import ToolsChangeDetectorRoute from 'routes/ChangeDetector'
import ToolsCorrelationFinderRoute from 'routes/tools/correlationFinder'
import ToolsCorrelationFinderResultRoute from 'routes/tools/correlationFinder/CorrelationFinderResult'
import ToolsPlotterRoute from 'routes/tools/plotter'
import ToolsTurboFishbonesRoute from 'routes/tools/turboFishbones'

import SpcProcessCapability from 'routes/Spc/process-capability'

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter)

const router = sentryCreateBrowserRouter([
  {
    path: '/',
    element: <RootRoute />,
    errorElement: <ErrorRoute />,
    children: [
      { path: '', element: <LandingRoute /> },
      { path: '/help', element: <HelpRoute /> },
      { path: '/plotter', element: <ToolsPlotterRoute /> },
      {
        path: '/correlation-finder',
        element: <ToolsCorrelationFinderRoute />,
      },
      { path: '/change-detector/:id?', element: <ToolsChangeDetectorRoute /> },
      {
        path: '/correlation-finder/result/:id?',
        element: <ToolsCorrelationFinderResultRoute />,
      },
      { path: '/turbo-fishbones', element: <ToolsTurboFishbonesRoute /> },

      { path: '/atlas/search', element: <SearchRoute /> },

      { path: '/atlas/datasets/new', element: <DatasetNewRouter /> },
      { path: '/atlas/datasets/:datasetId', element: <DatasetIdRouter /> },
      {
        path: '/atlas/datasets/:datasetId/edit',
        element: <DatasetEditRouter />,
      },

      { path: '/atlas/my-data', element: <MyDataRouter /> },
      {
        path: 'atlas/attributes/:attributeId',
        element: <AttributesRoute />,
      },

      { path: '/atlas/samples/new', element: <SampleNewRouter /> },
      { path: '/atlas/samples/:sampleId', element: <SampleIdRouter /> },
      { path: '/atlas/samples/:sampleId/edit', element: <SampleEditRouter /> },

      {
        path: '/atlas/attribute-collections/new',
        element: <AttributeCollectionNewRouter />,
      },
      {
        path: '/atlas/attribute-collections/:attributeId',
        element: <AttributeCollectionIdRouter />,
      },
      {
        path: '/atlas/attribute-collections/:attributeId/edit',
        element: <AttributeCollectionEditRouter />,
      },

      { path: '/tools/plotter', element: <Navigate to='/plotter' /> },
      {
        path: '/tools/change-detector',
        element: <Navigate to={'/change-detector'} />,
      },
      {
        path: '/tools/correlation-finder',
        element: <Navigate to={'/correlation-finder'} />,
      },
      {
        path: '/tools/correlation-finder/result/:id?',
        element: <ToolsCorrelationFinderResultRoute />,
      },
      {
        path: '/tools/turbo-fishbones',
        element: <Navigate to='/turbo-fishbones' />,
      },

      { path: '/spc/process-capability', element: <SpcProcessCapability /> },
    ],
  },
])

export default () => <RouterProvider router={router} />
