import ListItemIcon from '@mui/material/ListItemIcon'
import { ListItem, Typography } from '@northvolt/ui'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import type { SearchTag } from 'client/model'
import getSearchTagIcon from './SearchTagIcon'

interface SearchTagListItemProps {
  searchTag: SearchTag
  listItemProps?: any
  inputValue?: string
}

const SearchTagListItem: React.FC<SearchTagListItemProps> = ({ searchTag, listItemProps, inputValue }) => {
  const label = `${searchTag.key} = ${searchTag.value}`
  const matches = match(label, inputValue ? inputValue : '', {
    insideWords: true,
  })
  const parts: { text: string; highlight: boolean }[] = parse(label, matches)
  const { key, ...props } = listItemProps
  return (
    <ListItem component='li' sx={{ overflow: 'hidden' }} {...props}>
      <ListItemIcon sx={{ minWidth: 32 }}>{getSearchTagIcon(searchTag.key)}</ListItemIcon>
      <Typography variant='bodySmall'>
        {parts.map((part, index) => (
          <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
            {part.text}
          </span>
        ))}
      </Typography>
    </ListItem>
  )
}

export default SearchTagListItem
