/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * GigaWattson
 * OpenAPI spec version: 0.0.1
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import * as axios from 'axios';
import type {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse
} from 'axios'
import type {
  AttributeStatistics,
  DataLoadingParamsCreate,
  HTTPValidationError,
  LoadWombatFromDatasetParams
} from '.././model'



/**
 * @summary Load Wombat From Dataset
 */
export const loadWombatFromDataset = (
    datasetId: number,
    params: LoadWombatFromDatasetParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<string>> => {
    
    return axios.default.get(
      `/api/wombat/load_dataset/${datasetId}`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }


export const getLoadWombatFromDatasetQueryKey = (datasetId: number,
    params: LoadWombatFromDatasetParams,) => {
    return [`/api/wombat/load_dataset/${datasetId}`, ...(params ? [params]: [])] as const;
    }

    
export const getLoadWombatFromDatasetQueryOptions = <TData = Awaited<ReturnType<typeof loadWombatFromDataset>>, TError = AxiosError<HTTPValidationError>>(datasetId: number,
    params: LoadWombatFromDatasetParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof loadWombatFromDataset>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getLoadWombatFromDatasetQueryKey(datasetId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof loadWombatFromDataset>>> = ({ signal }) => loadWombatFromDataset(datasetId,params, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(datasetId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof loadWombatFromDataset>>, TError, TData> & { queryKey: QueryKey }
}

export type LoadWombatFromDatasetQueryResult = NonNullable<Awaited<ReturnType<typeof loadWombatFromDataset>>>
export type LoadWombatFromDatasetQueryError = AxiosError<HTTPValidationError>


/**
 * @summary Load Wombat From Dataset
 */

export function useLoadWombatFromDataset<TData = Awaited<ReturnType<typeof loadWombatFromDataset>>, TError = AxiosError<HTTPValidationError>>(
 datasetId: number,
    params: LoadWombatFromDatasetParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof loadWombatFromDataset>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getLoadWombatFromDatasetQueryOptions(datasetId,params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Load Wombat From Collection Samples
 */
export const loadWombatFromCollectionSamples = (
    dataLoadingParamsCreate: DataLoadingParamsCreate, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<string>> => {
    
    return axios.default.post(
      `/api/wombat/load_collection_samples/`,
      dataLoadingParamsCreate,options
    );
  }



export const getLoadWombatFromCollectionSamplesMutationOptions = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof loadWombatFromCollectionSamples>>, TError,{data: DataLoadingParamsCreate}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof loadWombatFromCollectionSamples>>, TError,{data: DataLoadingParamsCreate}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof loadWombatFromCollectionSamples>>, {data: DataLoadingParamsCreate}> = (props) => {
          const {data} = props ?? {};

          return  loadWombatFromCollectionSamples(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type LoadWombatFromCollectionSamplesMutationResult = NonNullable<Awaited<ReturnType<typeof loadWombatFromCollectionSamples>>>
    export type LoadWombatFromCollectionSamplesMutationBody = DataLoadingParamsCreate
    export type LoadWombatFromCollectionSamplesMutationError = AxiosError<HTTPValidationError>

    /**
 * @summary Load Wombat From Collection Samples
 */
export const useLoadWombatFromCollectionSamples = <TError = AxiosError<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof loadWombatFromCollectionSamples>>, TError,{data: DataLoadingParamsCreate}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof loadWombatFromCollectionSamples>>,
        TError,
        {data: DataLoadingParamsCreate},
        TContext
      > => {

      const mutationOptions = getLoadWombatFromCollectionSamplesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Read Attribute Statistics
 */
export const readAttributeStatistics = (
    attributeId: number, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<AttributeStatistics>> => {
    
    return axios.default.get(
      `/api/wombat/attribute_statistics/${attributeId}`,options
    );
  }


export const getReadAttributeStatisticsQueryKey = (attributeId: number,) => {
    return [`/api/wombat/attribute_statistics/${attributeId}`] as const;
    }

    
export const getReadAttributeStatisticsQueryOptions = <TData = Awaited<ReturnType<typeof readAttributeStatistics>>, TError = AxiosError<HTTPValidationError>>(attributeId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readAttributeStatistics>>, TError, TData>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getReadAttributeStatisticsQueryKey(attributeId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof readAttributeStatistics>>> = ({ signal }) => readAttributeStatistics(attributeId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(attributeId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof readAttributeStatistics>>, TError, TData> & { queryKey: QueryKey }
}

export type ReadAttributeStatisticsQueryResult = NonNullable<Awaited<ReturnType<typeof readAttributeStatistics>>>
export type ReadAttributeStatisticsQueryError = AxiosError<HTTPValidationError>


/**
 * @summary Read Attribute Statistics
 */

export function useReadAttributeStatistics<TData = Awaited<ReturnType<typeof readAttributeStatistics>>, TError = AxiosError<HTTPValidationError>>(
 attributeId: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof readAttributeStatistics>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getReadAttributeStatisticsQueryOptions(attributeId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



