import type { Table } from 'apache-arrow'

function arrowTableToCSV(table: Table): string {
  const tableRows = JSON.parse(table.toString())

  if (tableRows.length === 0) {
    return ''
  }

  const header = Object.keys(tableRows[0]).join(',')
  console.log(table.schema)
  const tsColumns = table.schema.fields.filter(
    field => field.metadata && field.metadata.get('Spark:DataType:SqlName') === 'TIMESTAMP',
  )
  const csv = tableRows
    .map((row: Record<string, unknown>) =>
      Object.entries(row)
        .map(([key, value]) => {
          // Check if the column is a timestamp
          if (tsColumns.some(tsColumn => tsColumn.name === key)) {
            try {
              // Try to format the timestamp as an ISO date string
              return new Date(value as number).toISOString()
            } catch (error) {
              return ''
            }
          }
          return value === null || value === undefined ? '' : value
        })
        .join(','),
    )
    .join('\n')

  return `${header}\n${csv}`
}

export function downloadCSV(table: Table, filename: string) {
  const csvContent = arrowTableToCSV(table)
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })

  const url = URL.createObjectURL(blob)

  // Create a hidden anchor element
  const link = document.createElement('a')
  link.style.display = 'none'
  document.body.appendChild(link)

  link.href = url
  link.download = filename
  link.click()

  document.body.removeChild(link)
}
