import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  List,
  Loader,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from '@northvolt/ui'
import {
  useDeleteAttributeCollection,
  useIsAttributeCollectionOwnedByUser,
  useReadAttributeCollection,
} from 'client/atlas/atlas'
import AttributeListItem from 'components/Attribute/AttributeListItem'
import ErrorCard from 'components/ErrorCard'
import { dateStringDisplayFormat } from 'components/Utils'
import React, { useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'

import { searchForTag } from 'components/SearchTag/SearchTagChip'

import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'

export default function Route() {
  const { attributeId } = useParams()
  const idNumber = Number.parseInt(attributeId || '', 10) || 0

  const navigate = useNavigate()

  const attributeCollectionLoader = useReadAttributeCollection(idNumber, {
    query: { retry: 1 },
  })
  const isUserOwner = useIsAttributeCollectionOwnedByUser(idNumber)

  const attributeCollectionDeleteMutation = useDeleteAttributeCollection()
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = React.useState(false)

  const handleDelete = () => {
    attributeCollectionDeleteMutation.mutate({
      attributeCollectionId: idNumber,
    })
  }

  useEffect(() => {
    if (attributeCollectionDeleteMutation.isSuccess) {
      setDeleteConfirmationOpen(false)
      navigate('/atlas/my-data')
    }
  }, [attributeCollectionDeleteMutation.isSuccess])

  if (attributeCollectionLoader.isLoading) {
    return (
      <Box display='flex' justifyContent='center' sx={{ py: 1, width: '100%' }}>
        <Loader type='linear' />
      </Box>
    )
  }
  if (attributeCollectionLoader.isError) {
    return (
      <ErrorCard
        title='Error'
        message={
          attributeCollectionLoader.error?.response?.data?.detail?.toString() || 'Attribute collection not found'
        }
      />
    )
  }
  const attribute_collection = attributeCollectionLoader.data?.data

  if (!attribute_collection) {
    return <ErrorCard title='Not Found' message={`Could not find attribute_collection with id: ${idNumber}`} />
  }

  const editButtons = () => {
    return (
      <>
        <Link to={`/atlas/attribute-collections/${idNumber}/edit`} style={{ color: 'inherit' }}>
          <Tooltip title='Edit attribute collection'>
            <IconButton size='small'>
              <EditIcon />
            </IconButton>
          </Tooltip>
        </Link>
        <IconButton size='small' onClick={() => setDeleteConfirmationOpen(true)}>
          <Tooltip title='Delete sample'>
            <DeleteIcon sx={{ color: 'error.main' }} />
          </Tooltip>
        </IconButton>
        <Dialog open={deleteConfirmationOpen} onClose={() => setDeleteConfirmationOpen(false)}>
          <DialogTitle>Confirm Deletion of Attribute Collection</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure that you want to delete the attribute collection
              <br />
              <span style={{ fontWeight: 700 }}>{attributeCollectionLoader.data?.data.name}</span>
              ?
              <br />
              This action cannot be undone.
              <br />
              (Datasets, including those of other users, that reference this attribute collection will be affected)
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              onClick={() => setDeleteConfirmationOpen(false)}
              color='secondary'
              disabled={attributeCollectionDeleteMutation.isLoading}>
              Cancel
            </Button>
            <Button onClick={handleDelete} color='error' disabled={attributeCollectionDeleteMutation.isLoading}>
              Confirm Deletion
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }

  return (
    <Grid container columns={12} spacing={2}>
      <Grid xs={12}>
        <Paper sx={{ p: 2 }}>
          <Stack direction={'row'}>
            <Box flexGrow={1}>
              <Typography
                variant='headlineMedium'
                title={attribute_collection?.name}
                sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                {attribute_collection?.grain} Attribute Collection / {attribute_collection?.name}
              </Typography>
              <Typography variant='captionSmall' color='text.secondary'>
                Created by {attribute_collection?.author.full_name} on{' '}
                {dateStringDisplayFormat(attribute_collection?.created_at?.toString())}
              </Typography>
            </Box>
            <Box>{isUserOwner?.data?.data === true && editButtons()}</Box>
          </Stack>
          <Stack direction='row' spacing={1} sx={{ mt: 2 }}>
            <Link
              to={{
                pathname: '/tools/plotter',
                search: `tab=1&attrCollIds=${attribute_collection?.id}`,
              }}>
              <Button size='small'>Plot Collection</Button>
            </Link>
          </Stack>
        </Paper>
      </Grid>

      <Grid xs={12}>
        <Paper sx={{ p: 2 }}>
          <Divider>
            <Typography variant='overline'>Description</Typography>
          </Divider>
          <Typography variant='bodyMedium'>{attribute_collection.description}</Typography>
        </Paper>
      </Grid>

      <Grid xs={12}>
        <Paper sx={{ p: 2 }}>
          <Divider>
            <Typography variant='overline'>Attributes</Typography>
          </Divider>
          <List sx={{ height: '40vh', overflow: 'auto' }}>
            {attribute_collection.attributes?.map(attr => (
              <AttributeListItem key={attr.id} attribute={attr} clickHandler={searchForTag} />
            ))}
          </List>
        </Paper>
      </Grid>
    </Grid>
  )
}
