import { ListItemText } from '@mui/material'
import { Box, List, ListItem, Loader, Typography } from '@northvolt/ui'
import { useReadAttributeCollectionsByAttribute } from 'client/atlas/atlas'
import ErrorCard from 'components/ErrorCard'
import { Link } from 'react-router-dom'

interface AttributeCollectionListCardProps {
  attributeId: number
}

const AttributeCollectionListCard: React.FC<AttributeCollectionListCardProps> = ({ attributeId }) => {
  const attributeCollectionLoader = useReadAttributeCollectionsByAttribute(attributeId)

  if (attributeCollectionLoader.isLoading) {
    return (
      <Box display='flex' justifyContent='center' sx={{ py: 1, width: '100%' }}>
        <Loader type='linear' />
      </Box>
    )
  }
  if (attributeCollectionLoader.isError) {
    return (
      <ErrorCard
        title='Error'
        message={attributeCollectionLoader.error?.response?.data?.detail?.toString() || 'Collections not found'}
      />
    )
  }
  if (!attributeCollectionLoader.data.data.items.length) {
    return <Typography variant='overline'>No collections found</Typography>
  }
  return (
    <List sx={{ p: 2 }}>
      {attributeCollectionLoader?.data.data.items.map(attributeCollection => (
        <ListItem key={attributeCollection.id}>
          <ListItemText>
            <Link to={`/atlas/attribute-collections/${attributeCollection.id}`} style={{ color: 'inherit' }}>
              <Typography title={attributeCollection.name} variant='subtitleMedium'>
                {attributeCollection.name}
              </Typography>
            </Link>
            <Typography variant='captionSmall' color='text.secondary'>
              {attributeCollection.description}
            </Typography>
          </ListItemText>
        </ListItem>
      ))}
    </List>
  )
}

export default AttributeCollectionListCard
