import type {
  AutocompleteRenderGetTagProps,
  FilledInputProps,
  FilterOptionsState,
  InputProps,
  OutlinedInputProps,
} from '@mui/material'
import { Autocomplete, CircularProgress, TextField, Tooltip } from '@northvolt/ui'
import { useDebounce } from '@uidotdev/usehooks'
import { useSearchAttributesByText } from 'client/atlas/atlas'
import type { Attribute, MaterialType } from 'client/model'
import React, { useEffect } from 'react'
import AttributeChip from './AttributeChip'
import AttributeSelectItem from './AttributeSelectItem'

export default ({
  selected,
  label,
  setSelected,
  grain,
  initialSuggestions = [],
  placeholder,
  disabled,
  multiple,
  tooltip = 'Free text search for attributes',
  filterOptions = () => true,
}: {
  selected: Attribute[]
  label: string
  setSelected: (attributes: Attribute[]) => void
  grain: MaterialType
  initialSuggestions?: Attribute[]
  placeholder?: string
  multiple?: boolean
  disabled?: boolean
  tooltip?: string
  filterOptions?: (attr: Attribute) => boolean
}) => {
  const [searchText, setSearchText] = React.useState<string>('')
  const [holder, placeHolder] = React.useState(placeholder || '')
  const debouncedSearchText = useDebounce(searchText, 300)
  const attributeLoader = useSearchAttributesByText()

  useEffect(() => {
    if (debouncedSearchText) {
      attributeLoader.mutate({
        params: { text: debouncedSearchText, grain: grain },
      })
    } else {
      attributeLoader.reset()
    }
  }, [debouncedSearchText])

  const getOptions = [
    ...new Set([...(attributeLoader.data?.data.items ?? initialSuggestions), ...(selected ?? [])]),
  ].filter(filterOptions)

  const handleChange = (attr: any) => {
    setSelected([...(multiple ? attr : [attr])])
    placeHolder(selected && placeholder ? placeholder : '')
  }

  const handleInputChange = (_event: React.SyntheticEvent, value: string) => {
    setSearchText(value)
  }

  return (
    <Tooltip title={tooltip} placement='top'>
      <Autocomplete
        size='small'
        options={getOptions}
        filterOptions={(options: Attribute[], _state: FilterOptionsState<Attribute>) => options}
        loading={attributeLoader.isLoading}
        value={multiple ? selected : selected?.[0]}
        onChange={(_, attr) => handleChange(attr)}
        onInputChange={handleInputChange}
        getOptionLabel={(attr: Attribute | string) => (typeof attr === 'object' ? attr.unique_name : '')}
        isOptionEqualToValue={(attr, other) => attr.id === other.id}
        renderOption={({ key, ...rest }, attr) => (
          <AttributeSelectItem attribute={attr} listItemProps={rest} searchText={searchText} key={attr.id} />
        )}
        renderInput={(params: {
          InputProps?: Partial<FilledInputProps | OutlinedInputProps | InputProps>
        }) => (
          <TextField
            {...params}
            label={label}
            placeholder={holder}
            variant='outlined'
            InputLabelProps={{ shrink: !!holder || undefined }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {attributeLoader.isLoading && <CircularProgress color='inherit' size={20} />}
                  {params.InputProps?.endAdornment}
                </>
              ),
            }}
          />
        )}
        renderTags={(value: any, getTagProps: AutocompleteRenderGetTagProps) =>
          value.map((attr: Attribute, index: number) => (
            <AttributeChip attribute={attr} key={index} chipProps={getTagProps({ index })} />
          ))
        }
        autoHighlight
        autoComplete
        fullWidth
        filterSelectedOptions
        noOptionsText='Empty or invalid search query (please type something).'
        disabled={disabled}
        multiple={multiple}
        disableCloseOnSelect={multiple}
      />
    </Tooltip>
  )
}
