import { FormControl } from '@mui/material'
import { MenuItem, Select, Tooltip } from '@northvolt/ui'
import type React from 'react'

const defaultOptions = [100, 1000, 5000, 10000, 25000, 50000, 100000, 250000]

interface RowLimitSelectorProps {
  rowLimit: number
  setRowLimit: (rowLimit: number) => void
  options?: number[]
}

const RowLimitSelector: React.FC<RowLimitSelectorProps> = ({ rowLimit, setRowLimit, options = defaultOptions }) => {
  return (
    <Tooltip placement='top-start' title={'The maximum number of items (e.g. cells) to load.'}>
      <FormControl fullWidth>
        <Select
          id='row-limit'
          label='Row Limit'
          labelId='row-limit-label'
          size='small'
          value={rowLimit}
          onChange={({ target: { value } }) => setRowLimit(value as number)}
          sx={{ width: '100%' }}>
          {options.map(v => (
            <MenuItem key={v} value={v}>
              {v}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Tooltip>
  )
}

export default RowLimitSelector
