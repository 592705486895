import { Button, Grid } from '@northvolt/ui'
import { FilterType, MaterialType } from 'client/model'
import type React from 'react'
import { useEffect, useState } from 'react'
import AttributeCollectionSearchAndSelect from './../AttributeCollection/AttributeCollectionSearchAndSelect'
import SampleSearchAndSelect from './../Sample/SampleSearchAndSelect'
import type { DataLoadingParams } from './DataLoaderTypes'

import { parseAsArrayOf, parseAsBoolean, parseAsInteger, parseAsString, useQueryParam } from './../useQueryHook'

import { useReadAttributeCollectionsByIds, useReadSamplesByIds } from 'client/atlas/atlas'
import Filter from 'components/Filter'
import useDeepCompareEffect from 'use-deep-compare-effect'
import GrainSelector from './GrainSelector'
import RandomizeSwitch from './RandomizeSwitch'
import RowLimitSelector from './RowLimitSelector'
import StratificationControl from './StratificationControl'

type AdhocSelectorProps = {
  dataLoadingParams: DataLoadingParams
  setDataLoadingParams: (dataLoadingParams: DataLoadingParams) => void
  disabled?: boolean
}

const AdhocSelector: React.FC<AdhocSelectorProps> = ({ dataLoadingParams, setDataLoadingParams, disabled }) => {
  const [rowLimit, setRowLimit] = useQueryParam('rowLimit', 1000, parseAsInteger)
  const [randomize, setRandomize] = useQueryParam('randomize', false, parseAsBoolean)

  const [stratificationColUniqueName, setStratificationColUniqueName] = useQueryParam(
    'stratificationColUniqueName',
    undefined,
    parseAsString,
  )

  const [selectedAttributeCollectionIds, setSelectedAttributeCollectionIds] = useQueryParam(
    'attrCollIds',
    [],
    parseAsArrayOf(parseAsInteger),
  )
  const [selectedSampleIds, setSelectedSampleIds] = useQueryParam('sampleIds', [], parseAsArrayOf(parseAsInteger))

  const [selectedParams, setSelectedParams] = useState<DataLoadingParams>({
    ...dataLoadingParams,
    name: 'Ad-hoc',
    grain: MaterialType.Prismatic_Cell,
    filters: [],
    row_limit: rowLimit,
    randomize: randomize,
    stratification_col_unique_name: stratificationColUniqueName,
  })

  const attributeCollectionLoader = useReadAttributeCollectionsByIds()
  const sampleLoader = useReadSamplesByIds()

  useEffect(() => {
    const attributeCollections = attributeCollectionLoader.data?.data?.items
    if (attributeCollectionLoader.isSuccess) {
      setSelectedParams({
        ...selectedParams,
        attribute_collections: attributeCollections,
      })
    }
  }, [attributeCollectionLoader.data?.data])

  useEffect(() => {
    const samples = sampleLoader.data?.data?.items
    if (sampleLoader.isSuccess) {
      setSelectedParams({
        ...selectedParams,
        samples: samples,
      })
    }
  }, [sampleLoader.data?.data])

  useDeepCompareEffect(() => {
    if (selectedAttributeCollectionIds?.length > 0) {
      attributeCollectionLoader.mutate({
        data: selectedAttributeCollectionIds,
      })
    } else {
      attributeCollectionLoader.reset()
      setSelectedParams({
        ...selectedParams,
        attribute_collections: [],
      })
    }
  }, [selectedAttributeCollectionIds])

  useDeepCompareEffect(() => {
    if (selectedSampleIds?.length > 0) {
      sampleLoader.mutate({ data: selectedSampleIds })
    } else {
      sampleLoader.reset()
      setSelectedParams({
        ...selectedParams,
        samples: [],
      })
    }
  }, [selectedSampleIds])

  useEffect(() => {
    setSelectedParams({
      ...selectedParams,
      row_limit: rowLimit,
    })
  }, [rowLimit])

  useEffect(() => {
    setSelectedParams({
      ...selectedParams,
      randomize: randomize,
    })
  }, [randomize])

  useEffect(() => {
    setSelectedParams({
      ...selectedParams,
      stratification_col_unique_name: stratificationColUniqueName,
    })
  }, [stratificationColUniqueName])

  const allFiltersAreDefined = () => {
    const allFiltersHaveAttributes = selectedParams.filters?.every(filter => filter.attribute)
    const allFiltersHaveType = selectedParams.filters?.every(filter => filter.type)
    const allFiltersHaveValues = selectedParams.filters?.every(filter => {
      switch (filter.type) {
        case FilterType.null:
          return true
        case FilterType.not_null:
          return true
        case FilterType.number_range:
          return filter.min_number && filter.max_number
        case FilterType.datetime_range:
          return filter.min_datetime && filter.max_datetime
        default:
          return filter.multiple ? filter.multiple_values?.length : filter.value
      }
    })
    return allFiltersHaveAttributes && allFiltersHaveType && allFiltersHaveValues
  }

  const canSubmit = () => {
    return (
      selectedParams.attribute_collections?.length &&
      selectedParams.samples?.length &&
      selectedParams.row_limit &&
      allFiltersAreDefined()
    )
  }

  const submit = () => {
    setDataLoadingParams(selectedParams)
  }

  return (
    <Grid container spacing={{ xs: 1 }} columns={{ xs: 6, lg: 12 }} sx={{ mt: 1 }}>
      <Grid xs={2}>
        <GrainSelector
          grain={selectedParams.grain}
          setGrain={(grain: MaterialType) => setSelectedParams({ ...selectedParams, grain: grain })}
        />
      </Grid>
      <Grid xs={5}>
        <AttributeCollectionSearchAndSelect
          label='Select Attribute Collections'
          selectedAttributeCollections={
            selectedParams.attribute_collections ? selectedParams.attribute_collections : []
          }
          setSelectedAttributeCollections={attributeCollections =>
            setSelectedAttributeCollectionIds(attributeCollections.map(ac => ac.id))
          }
          grain={selectedParams.grain}
          multiple={true}
        />
      </Grid>
      <Grid xs={5}>
        <SampleSearchAndSelect
          selectedSamples={selectedParams.samples ? selectedParams.samples : []}
          setSelectedSamples={samples => setSelectedSampleIds(samples.map(s => s.id))}
          grain={selectedParams.grain}
          multiple={true}
        />
      </Grid>

      <Grid xs={2}>
        <RowLimitSelector rowLimit={rowLimit} setRowLimit={setRowLimit} />
      </Grid>

      <Grid xs={2}>
        <RandomizeSwitch randomize={randomize} setRandomize={setRandomize} />
      </Grid>

      <Grid xs={5}>
        <StratificationControl
          attributeCollections={selectedParams.attribute_collections ? selectedParams.attribute_collections : []}
          stratificationAttributeName={stratificationColUniqueName}
          setStratificationAttributeName={attributeName => setStratificationColUniqueName(attributeName)}
        />
      </Grid>
      <Grid xs={12}>
        <Filter
          elements={[
            ...(selectedParams.attribute_collections
              ? selectedParams.attribute_collections.flatMap(({ attributes }) => attributes)
              : []),
          ]}
          set={filters => setSelectedParams({ ...selectedParams, filters: filters })}
          disabled={disabled}
          grain={selectedParams.grain}
        />
      </Grid>
      <Grid xs={2}>
        <Button variant='contained' size='medium' disabled={!canSubmit()} onClick={submit}>
          Load
        </Button>
      </Grid>
    </Grid>
  )
}

export default AdhocSelector
