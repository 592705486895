import { Box, ListItem, Stack, Typography } from '@northvolt/ui'
import type { AttributeCollectionLight } from 'client/model'
import { Link } from 'react-router-dom'
import AttributeChip from '../Attribute/AttributeChip'
import { dateStringDisplayFormat } from './../Utils'

interface AttributeCollectionListItemProps {
  attributeCollection: AttributeCollectionLight
  minimal?: boolean
}

const AttributeCollectionListItem: React.FC<AttributeCollectionListItemProps> = ({ attributeCollection, minimal }) => {
  return (
    <ListItem
      key={attributeCollection.id}
      sx={{
        p: 2,
        border: '1px solid #E0E0E0',
        borderRadius: 1,
        height: '100%',
      }}
      alignItems='flex-start'>
      <Stack alignItems='left' sx={{ overflow: 'hidden' }}>
        <Link to={`/atlas/attribute-collections/${attributeCollection.id}`} style={{ color: 'inherit' }}>
          <Typography title={attributeCollection.name} variant='subtitleMedium'>
            <strong>{attributeCollection.name}</strong>
          </Typography>
        </Link>
        <Typography variant='bodySmall'>{attributeCollection.description}</Typography>
        <Typography variant='captionSmall' color='text.secondary'>
          Created by {attributeCollection.author.full_name} on{' '}
          {dateStringDisplayFormat(attributeCollection.created_at?.toString(), true)}
        </Typography>
        {!minimal && (
          <Box>
            {attributeCollection.attributes?.map(attr => (
              <AttributeChip key={attr.id} attribute={attr} clickable />
            ))}
          </Box>
        )}
      </Stack>
    </ListItem>
  )
}

export default AttributeCollectionListItem
