import ReadMoreIcon from '@mui/icons-material/ReadMore'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import ListItemIcon from '@mui/material/ListItemIcon'
import { IconButton, ListItem, Stack, Tooltip, Typography } from '@northvolt/ui'
import { Link } from 'react-router-dom'
import type { SelectableAttribute } from '../DataLoader/DataLoaderTypes'
import { getHighlightedText } from './../Utils'

interface AttributeSelectItemProps {
  attribute: SelectableAttribute
  listItemProps?: any
  searchText?: string
}

const AttributeSelectItem: React.FC<AttributeSelectItemProps> = ({ attribute, listItemProps, searchText }) => {
  return (
    <Tooltip placement='top' title={attribute.warningReason}>
      <div>
        <ListItem key={attribute.id} divider {...listItemProps}>
          <Stack alignItems='left' sx={{ overflow: 'hidden', width: '100%' }}>
            <Typography title={attribute.unique_name} variant='subtitleMedium'>
              {getHighlightedText(attribute.unique_name, searchText)}
            </Typography>
            <Typography variant='captionSmall' color='text.secondary'>
              {getHighlightedText(attribute?.column_description || '', searchText)}
            </Typography>
            <Typography variant='captionSmall' color='text.secondary' title={attribute.data_type}>
              {attribute.data_type} attribute of a {attribute.grain}
            </Typography>
          </Stack>
          {attribute.warning && (
            <ListItemIcon>
              <WarningAmberIcon />
            </ListItemIcon>
          )}
          <IconButton edge='end' size='small' onClick={e => e.stopPropagation()} title='Read More'>
            <Link to={`/atlas/attributes/${attribute.id}`} style={{ color: 'inherit' }} target='_blank'>
              <ReadMoreIcon />
            </Link>
          </IconButton>
        </ListItem>
      </div>
    </Tooltip>
  )
}

export default AttributeSelectItem
