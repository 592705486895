import { Box, Divider, Grid, Loader, Paper, Stack, Typography } from '@northvolt/ui'

import { useReadAttribute } from 'client/atlas/atlas'
import AddToCollectionPopover from 'components/AttributeCollection/AddToCollectionPopover'
import ErrorCard from 'components/ErrorCard'
import SearchTagChip, { searchForTag } from 'components/SearchTag/SearchTagChip'
import { useParams } from 'react-router-dom'
import AttributeCollectionListCard from './AttributeCollectionListCard'
import AttributeStats from './AttributeStats'

import { getAttributePrompts } from 'components/Attribute/Prompts'
import AskGigaWattson from 'components/GigaWattson/AskGigaWattson'

export default function Page() {
  const { attributeId } = useParams()
  const idNumber = Number.parseInt(attributeId || '', 10) || 0
  const attributeLoader = useReadAttribute(idNumber, { query: { retry: 1 } })

  if (attributeLoader.isLoading) {
    return (
      <Box display='flex' justifyContent='center' sx={{ py: 1, width: '100%' }}>
        <Loader type='linear' />
      </Box>
    )
  }
  if (attributeLoader.isError) {
    return (
      <ErrorCard
        title='Error'
        message={attributeLoader.error?.response?.data?.detail?.toString() || 'Attribute not found'}
      />
    )
  }
  const attribute = attributeLoader.data?.data

  return (
    <Grid container columns={12} spacing={2}>
      <Grid xs={12}>
        <Paper sx={{ p: 2 }}>
          <Typography
            variant='headlineMedium'
            title={attribute?.unique_name}
            sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
            {attribute?.domain} / {attribute?.grain} Attribute / {attribute?.unique_name}
          </Typography>
          <Typography variant='captionSmall' color='text.secondary'>
            {attribute?.qualified_name}
          </Typography>
          <Stack direction='row' spacing={1} sx={{ mt: 2 }}>
            <AddToCollectionPopover attribute={attribute} />
            <AskGigaWattson prompts={getAttributePrompts(attribute)} />
          </Stack>
        </Paper>
      </Grid>

      <Grid xs={12}>
        <Paper sx={{ px: 2, pb: 2, pt: 1, height: '100%' }}>
          <Divider>
            <Typography variant='overline'>Tags</Typography>
          </Divider>
          {attribute?.search_tags
            ?.sort((a, b) => a.key.localeCompare(b.key))
            .map((tag, index) => (
              <SearchTagChip key={`chip-${index}`} searchTag={tag} clickHandler={searchForTag} />
            ))}
        </Paper>
      </Grid>

      <Grid xs={12} md={6}>
        <Paper sx={{ px: 2, pb: 2, pt: 1, height: '100%' }}>
          <Divider>
            <Typography variant='overline'>Description</Typography>
          </Divider>
          <Typography variant='bodyMedium'>
            {attribute?.column_description ? attribute.column_description : 'No description available'}
          </Typography>
        </Paper>
      </Grid>

      <Grid xs={12} md={6}>
        <Paper sx={{ pt: 1, height: '100%' }}>
          <Divider>
            <Typography variant='overline'>Data Insights</Typography>
          </Divider>
          <AttributeStats id={idNumber} />
        </Paper>
      </Grid>

      <Grid xs={12} md={6}>
        <Paper sx={{ px: 2, pb: 2, pt: 1, height: '100%' }}>
          <Divider>
            <Typography variant='overline'>Attribute Collections</Typography>
          </Divider>
          <Typography variant='bodyMedium'>This attribute appears in the following collections:</Typography>
          <AttributeCollectionListCard attributeId={idNumber} />
        </Paper>
      </Grid>
    </Grid>
  )
}
