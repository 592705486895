import Battery0BarOutlinedIcon from '@mui/icons-material/Battery0BarOutlined'
import { Stack } from '@mui/material'
import { Card, CardContent, CardHeader, Divider, List, Typography } from '@northvolt/ui'
import type { Dataset } from 'client/model'
import type React from 'react'
import { Link } from 'react-router-dom'
import AttributeCollectionListItem from '../AttributeCollection/AttributeCollectionListItem'
import SampleListItem from '../Sample/SampleListItem'
import { dateStringDisplayFormat } from './../Utils'

interface DatasetCardProps {
  dataset: Dataset
}

const DatasetCard: React.FC<DatasetCardProps> = ({ dataset }) => {
  const subHeader = `Created by ${dataset?.author.full_name} on ${dateStringDisplayFormat(dataset?.created_at?.toString(), true)}`
  return (
    <Card
      sx={{
        minHeight: 200,
        p: 1,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}>
      <Link to={`/atlas/datasets/${dataset.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
        <Stack direction='row' spacing={1} alignItems='top'>
          <Battery0BarOutlinedIcon sx={{ mt: 1.25, ml: 1 }} style={{ fontSize: 30 }} />
          <CardHeader title={dataset?.name} subheader={subHeader} sx={{ pt: 1, px: 0 }} />
        </Stack>
      </Link>
      <CardContent>
        <Divider>
          <Typography variant='overline'>Description</Typography>
        </Divider>
        <Typography variant='bodySmall'>{dataset?.description}</Typography>
      </CardContent>
      <CardContent>
        <Divider>
          <Typography variant='overline'>Attribute Collections</Typography>
        </Divider>
        <List sx={{ overflow: 'auto' }}>
          {dataset?.attribute_collections?.map(attrCol => (
            <AttributeCollectionListItem key={attrCol.id} attributeCollection={attrCol} minimal />
          ))}
        </List>
        <Divider>
          <Typography variant='overline'>Samples</Typography>
        </Divider>
        <List sx={{ overflow: 'auto' }}>
          {dataset?.samples?.map(sample => (
            <SampleListItem key={sample.id} sample={sample} minimal />
          ))}
        </List>
      </CardContent>
    </Card>
  )
}

export default DatasetCard
