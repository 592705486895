import { Button, FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { Switch } from '@northvolt/ui'
import { type Reducer, useEffect, useReducer } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  useSearchAttributeCollectionsByText,
  useSearchAttributesByText,
  useSearchSamplesByText,
} from 'client/atlas/atlas'
import { useRunCorrelationFinder } from 'client/correlation-finder/correlation-finder'
import {
  type Attribute,
  type AttributeCollection,
  type AttributeCollectionLight,
  type DataLoadingParamsLight,
  MaterialType,
  type Sample,
  type SampleLight,
} from 'client/model'
import AttributeCollectionSearchAndSelect from 'components/AttributeCollection/AttributeCollectionSearchAndSelect'

import AttributeSearchAndSelect from 'components/Attribute/AttributeSearchAndSelect'
import StratificationControl from 'components/DataLoader/StratificationControl'
import SampleSearchAndSelect from 'components/Sample/SampleSearchAndSelect'
import { type CorrelationState, CorrelationStateEnum } from './Types'

import Filter from 'components/Filter'
import type { Filter as FilterType } from 'components/Filter/Types'
import { allowedGrains, isDateOrTime } from 'components/Utils'
import styles from './correlation-finder.module.scss'

const limitValues = [1000, 5000, 10000, 25000, 50000, 100000, 250000]

const keyMap = {
  blocklist: 'attribute_blocklist',
  attributeCollections: 'attribute_collection_ids',
  samples: 'sample_ids',
} as const

const setBlockSubmit = ({ data_loading_params, sending, sample_mode, target_attribute }: CorrelationState) => {
  if (sending) return true
  if (sample_mode) {
    if (data_loading_params?.sample_ids?.length < 2 || !data_loading_params?.attribute_collection_ids?.length) {
      return true
    }
    return false
  }
  if (data_loading_params?.filters?.some(({ attribute_id }) => !attribute_id)) {
    return true
  }
  if (
    !target_attribute?.id ||
    !data_loading_params?.attribute_collection_ids?.length ||
    !data_loading_params?.sample_ids?.length
  ) {
    return true
  }
  return false
}

type Action = {
  type: CorrelationStateEnum
  payload: any
}

const correlationReducer = (state: CorrelationState, action: Action): CorrelationState => {
  const { type, payload } = action
  if (payload === undefined) return state
  switch (type) {
    case CorrelationStateEnum.INIT: {
      return {
        ...state,
        ...payload,
        blockSubmit: setBlockSubmit(payload),
      }
    }
    case CorrelationStateEnum.TARGET_ATTRIBUTE: {
      const newState = {
        ...state,
        target_attribute: payload,
      }
      return {
        ...newState,
        blockSubmit: setBlockSubmit(newState),
      }
    }
    case CorrelationStateEnum.DATA_LOADING_PARAMS: {
      const newState = {
        ...state,
        data_loading_params: {
          ...state.data_loading_params,
          ...payload,
        },
      }
      return {
        ...newState,
        blockSubmit: setBlockSubmit(newState),
      }
    }
    case CorrelationStateEnum.FILTERS: {
      const newState = {
        ...state,
        data_loading_params: {
          ...state.data_loading_params,
          filters: payload,
        },
      }
      return {
        ...newState,
        blockSubmit: setBlockSubmit(newState),
      }
    }
    case CorrelationStateEnum.SAMPLES:
    case CorrelationStateEnum.BLOCKLIST:
    case CorrelationStateEnum.ATTRIBUTECOLLECTIONS: {
      const newState = {
        ...state,
        data_loading_params: {
          ...state.data_loading_params,
          [keyMap[type]]: (payload as AttributeCollectionLight[] | SampleLight[] | Attribute[]).map(
            ({ id }: { id: number }) => id,
          ),
        },
        [type]: payload as AttributeCollectionLight[] | SampleLight[] | Attribute[],
      }
      return {
        ...newState,
        blockSubmit: setBlockSubmit(newState),
      }
    }
    case CorrelationStateEnum.SAMPLE_MODE: {
      const newState = {
        ...state,
        sample_mode: payload,
      }
      return {
        ...newState,
        blockSubmit: setBlockSubmit(newState),
      }
    }
    default: {
      return {
        ...state,
        blockSubmit: setBlockSubmit(state),
        [type]: payload,
      }
    }
  }
}

type CorrelationData =
  | {
      data_loading_params?: DataLoadingParamsLight
      target_attribute?: Attribute
      sample_mode?: boolean
    }
  | undefined

const initialState = {
  data_loading_params: {
    filters: [] as FilterType<Attribute>[],
    grain: MaterialType.Prismatic_Cell,
    row_limit: undefined,
    randomize: true,
    attribute_collection_ids: [] as number[],
    attribute_blocklist: [] as number[],
    sample_ids: [] as number[],
    stratification_col_unique_name: '',
  },
  target_attribute: {
    column_description: '',
    column_name: '',
    data_type: '',
    id: 0,
    grain: MaterialType.Prismatic_Cell,
    unique_name: '',
  },
  attributeCollections: [] as AttributeCollection[],
  samples: [] as Sample[],
  blocklist: [] as Attribute[],
  suggestedAttributeCollections: [] as AttributeCollection[],
  suggestedSamples: [] as Sample[],
  suggestedTargets: [] as Attribute[],
  suggestedBlocklist: [] as Attribute[],
  blockSubmit: true,
  sample_mode: false,
  errors: [] as string[],
  sending: false,
} as CorrelationState

export default (data: CorrelationData) => {
  const createInitialState = (data: CorrelationData): CorrelationState => {
    if (!data) return initialState
    return {
      ...initialState,
      data_loading_params: {
        filters: data.data_loading_params?.filters,
        grain: data.data_loading_params?.grain || MaterialType.Prismatic_Cell,
        row_limit: data.data_loading_params?.row_limit,
        randomize: data.data_loading_params?.randomize,
        stratification_col_unique_name: data.data_loading_params?.stratification_col_unique_name,
        attribute_collection_ids: data.data_loading_params?.attribute_collections.map(({ id }) => id),
        attribute_blocklist: data.data_loading_params?.attribute_blocklist.map(({ id }) => id),
        sample_ids: data.data_loading_params?.samples.map(({ id }) => id),
      },
      target_attribute: {
        column_description: data.target_attribute?.column_description || '',
        column_name: data.target_attribute?.column_name || '',
        data_type: data.target_attribute?.data_type || '',
        id: data.target_attribute?.id || 0,
        grain: data.target_attribute?.grain || MaterialType.Prismatic_Cell,
        unique_name: data.target_attribute?.unique_name || '',
      },
      attributeCollections: data.data_loading_params?.attribute_collections.slice(0),
      samples: data.data_loading_params?.samples.slice(0),
      blocklist: data.data_loading_params?.attribute_blocklist.slice(0),
      blockSubmit:
        (!data.sample_mode && !data.target_attribute?.id) ||
        !data.data_loading_params?.attribute_collections?.length ||
        !data.data_loading_params.samples?.length,
      sample_mode: !!data.sample_mode,
      errors: [] as string[],
      sending: false,
    } as CorrelationState
  }

  const [payload, dispatch] = useReducer<Reducer<CorrelationState, Action>>(
    correlationReducer,
    createInitialState(data),
  )
  const canFilter =
    (!payload.sample_mode &&
      payload.target_attribute?.id &&
      payload.attributeCollections?.length &&
      payload.samples?.length) ||
    (payload.sample_mode && payload.samples?.length && payload.attributeCollections?.length)

  const attributeCollectionsLoader = useSearchAttributeCollectionsByText()
  const attributeLoader = useSearchAttributesByText()
  const correlationLoader = useRunCorrelationFinder()
  const sampleLoader = useSearchSamplesByText()
  const navigate = useNavigate()

  useEffect(() => {
    attributeCollectionsLoader.mutate({
      params: {
        text: 'all',
        only_user_collections: false,
        grain: payload.data_loading_params.grain,
      },
    })
    sampleLoader.mutate({
      params: {
        text: 'a',
        grain: payload.data_loading_params.grain,
      },
    })
    attributeLoader.mutate({
      params: {
        text: 'a',
        grain: payload.data_loading_params.grain,
      },
    })
  }, [])

  const handleSubmit = () => {
    dispatch({ type: CorrelationStateEnum.SENDING, payload: true })
    const { data_loading_params, target_attribute, sample_mode } = payload
    const data = sample_mode
      ? {
          data: { data_loading_params },
          params: { sample_mode },
        }
      : {
          data: { data_loading_params, target_attribute },
        }
    correlationLoader.mutate(data)
  }

  useEffect(() => {
    const { isSuccess, isError, data, error } = correlationLoader
    if (isError) {
      dispatch({
        type: CorrelationStateEnum.ERRORS,
        payload: [error.response?.data.detail],
      })
      return
    }

    if (!isSuccess) return
    const pageParams = {
      targetName: payload.target_attribute?.unique_name,
      sampleNames: payload.samples.map(({ name }) => name),
      attributeCollectionNames: payload.attributeCollections.map(({ name }) => name),
    }
    const current = localStorage.getItem('__correlation-finder')
    const state = current ? JSON.parse(current) : {}
    localStorage.setItem('__correlation-finder', JSON.stringify({ ...state, [data.data.id]: pageParams }))
    dispatch({ type: CorrelationStateEnum.SENDING, payload: false })
    navigate(`/tools/correlation-finder/result/${correlationLoader.data.data.id}`)
  }, [correlationLoader])

  useEffect(() => {
    if (attributeCollectionsLoader.data) {
      const items = attributeCollectionsLoader.data?.data.items
      const suggestedAttributeCollections = items.slice(0, 5)
      dispatch({
        type: CorrelationStateEnum.SUGGESTEDATTRIBUTECOLLECTIONS,
        payload: suggestedAttributeCollections,
      })
    }
  }, [attributeCollectionsLoader.data])

  useEffect(() => {
    if (sampleLoader.data) {
      const items = sampleLoader.data?.data.items
      const suggestedSamples = items.slice(0, 5)
      dispatch({
        type: CorrelationStateEnum.SUGGESTEDSAMPLES,
        payload: suggestedSamples,
      })
    }
  }, [sampleLoader.data])

  useEffect(() => {
    if (attributeLoader.data) {
      const items = attributeLoader.data?.data.items
      const suggestedTargets = items.slice(6)
      dispatch({
        type: CorrelationStateEnum.SUGGESTEDTARGETS,
        payload: suggestedTargets,
      })
      dispatch({
        type: CorrelationStateEnum.SUGGESTEDBLOCKLIST,
        payload: suggestedTargets,
      })
    }
  }, [attributeLoader.data])

  useEffect(() => {
    dispatch({
      type: CorrelationStateEnum.INIT,
      payload: createInitialState(data),
    })
  }, [data])

  const filterOptions = (attr: Attribute) =>
    !!attr &&
    !isDateOrTime(attr) &&
    !payload.blocklist.includes(attr) &&
    !attr.column_name.includes('cell_id') &&
    !attr.column_name.includes('pr_id')

  return (
    <div className={styles.formGroup}>
      <div className={styles.formContainer}>
        <div className={styles.sample_mode}>
          <Switch
            label='Sample mode'
            checked={payload.sample_mode}
            onChange={() =>
              dispatch({
                type: CorrelationStateEnum.SAMPLE_MODE,
                payload: !payload.sample_mode,
              })
            }
          />
        </div>

        <div className={styles.grain}>
          <FormControl fullWidth>
            <InputLabel id='grain-label'>Grain</InputLabel>
            <Select
              id='grain'
              label='Grain'
              labelId='grain-label'
              size='small'
              value={payload.data_loading_params.grain}
              onChange={({ target }) => {
                dispatch({
                  type: CorrelationStateEnum.DATA_LOADING_PARAMS,
                  payload: { grain: target.value },
                })
              }}
              fullWidth
              required>
              {Object.values(MaterialType).map(materialType => (
                <MenuItem key={materialType} value={materialType} disabled={!allowedGrains.includes(materialType)}>
                  {materialType}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        {!payload.sample_mode && (
          <AttributeSearchAndSelect
            label='Correlation target'
            placeholder='Select a target attribute'
            initialSuggestions={payload.suggestedTargets}
            selected={[
              ...(data?.target_attribute
                ? [data.target_attribute]
                : payload?.target_attribute
                  ? [payload.target_attribute]
                  : []),
            ]}
            setSelected={(target: Attribute[]) =>
              dispatch({
                type: CorrelationStateEnum.TARGET_ATTRIBUTE,
                payload: target?.[0],
              })
            }
            grain={payload.data_loading_params.grain}
            multiple={false}
            {...filterOptions}
          />
        )}
      </div>
      <div className={styles.formContainer}>
        <AttributeCollectionSearchAndSelect
          label='Attribute Collection'
          placeholder='Select attribute collections'
          suggestedAttributeCollections={payload.suggestedAttributeCollections ?? []}
          selectedAttributeCollections={
            payload.attributeCollections ?? data?.data_loading_params?.attribute_collections ?? []
          }
          setSelectedAttributeCollections={attributeCollections =>
            dispatch({
              type: CorrelationStateEnum.ATTRIBUTECOLLECTIONS,
              payload: attributeCollections,
            })
          }
          grain={payload.data_loading_params.grain}
          multiple={true}
        />

        <SampleSearchAndSelect
          label='Samples'
          placeholder={payload.sample_mode ? 'Select at least two samples' : 'Select samples'}
          suggestedSamples={payload.suggestedSamples ?? []}
          selectedSamples={payload.samples ?? data?.data_loading_params?.samples ?? []}
          setSelectedSamples={samples => dispatch({ type: CorrelationStateEnum.SAMPLES, payload: samples })}
          grain={payload.data_loading_params.grain}
          multiple={true}
        />
      </div>

      <div className={styles.formContainerReverse}>
        <AttributeSearchAndSelect
          label='Blocklist'
          placeholder='Select attributes to ignore in the calculations'
          initialSuggestions={payload.suggestedBlocklist}
          selected={payload.blocklist ?? (data?.data_loading_params?.attribute_blocklist as Attribute[]) ?? []}
          setSelected={blocklist => {
            if (Array.isArray(blocklist))
              dispatch({
                type: CorrelationStateEnum.BLOCKLIST,
                payload: blocklist,
              })
          }}
          grain={payload.data_loading_params.grain}
          multiple={true}
          filterOptions={attr => !!attr && attr.id !== payload.target_attribute?.id}
        />

        <div className={styles.rowLimit}>
          <FormControl>
            <InputLabel id='row-limit-label'>Row Limit </InputLabel>
            <Select
              id='row-limit'
              label='Row Limit'
              labelId='row-limit-label'
              size='small'
              defaultValue={payload.data_loading_params.row_limit ?? 1000}
              value={data?.data_loading_params?.row_limit ?? payload.data_loading_params.row_limit ?? 1000}
              onChange={({ target }) =>
                dispatch({
                  type: CorrelationStateEnum.DATA_LOADING_PARAMS,
                  payload: { row_limit: target.value },
                })
              }
              sx={{ width: '100%' }}>
              {limitValues.map(v => (
                <MenuItem key={v} value={v}>
                  {v}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <div className={styles.random}>
          <Switch
            label='Randomize'
            checked={payload.data_loading_params.randomize}
            onChange={() =>
              dispatch({
                type: CorrelationStateEnum.DATA_LOADING_PARAMS,
                payload: { randomize: !payload.data_loading_params.randomize },
              })
            }
          />
        </div>
      </div>

      <div className={styles.formContainerReverse}>
        <div className={styles.stratify}>
          <StratificationControl
            attributeCollections={
              payload.attributeCollections ?? data?.data_loading_params?.attribute_collections ?? []
            }
            stratificationAttributeName={
              payload.data_loading_params.stratification_col_unique_name ??
              data?.data_loading_params?.stratification_col_unique_name ??
              ''
            }
            setStratificationAttributeName={attributeName =>
              dispatch({
                type: CorrelationStateEnum.DATA_LOADING_PARAMS,
                payload: { stratification_col_unique_name: attributeName },
              })
            }
          />
        </div>
      </div>

      <Filter
        elements={[
          ...(payload?.attributeCollections ?? data?.data_loading_params?.attribute_collections ?? []),
        ].flatMap(({ attributes }) => attributes)}
        initial={(data?.data_loading_params?.filters ?? []) as FilterType<any>[]}
        set={(filters: FilterType<Attribute>[]) => {
          dispatch({
            type: CorrelationStateEnum.FILTERS,
            payload: filters,
          })
        }}
        grain={payload.data_loading_params.grain}
        disabled={!canFilter}
      />

      <Button fullWidth variant='contained' size='medium' disabled={payload.blockSubmit} onClick={handleSubmit}>
        Run Correlation Finder
      </Button>
      {!!payload.errors?.length &&
        payload.errors?.map(error => (
          <p key={error} className={styles.error}>
            {error}
          </p>
        ))}
    </div>
  )
}
