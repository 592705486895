import type { FilterOptionsState } from '@mui/material'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import { MaterialType } from 'client/model'
import { matchSorter } from 'match-sorter'
import type { SelectableAttribute } from './DataLoader/DataLoaderTypes'

export const allowedGrains: MaterialType[] = [
  MaterialType.Prismatic_Cell,
  MaterialType.Prismatic_Jelly_Roll,
  MaterialType.Anode_Sheet,
  MaterialType.Cathode_Sheet,
]

const dateFormat: Intl.DateTimeFormatOptions = {
  weekday: 'long',
  year: 'numeric',
  month: 'short',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  hour12: false,
}
const shortDateFormat: Intl.DateTimeFormatOptions = {
  year: '2-digit',
  month: '2-digit',
  day: '2-digit',
}

export function getAttributeType(attribute?: SelectableAttribute) {
  if (isNumeric(attribute)) {
    return AttributeType.numeric
  }
  if (isDateOrTime(attribute)) {
    return AttributeType.datetime
  }
  return AttributeType.categorical
}

export enum AttributeType {
  numeric = 0,
  datetime = 1,
  categorical = 2,
}

// TODO: generate in backend
export enum DataType {
  BIGINT = 'bigint',
  BOOLEAN = 'boolean',
  DATE = 'date',
  DOUBLE = 'double',
  INT = 'int',
  STRING = 'string',
  TIMESTAMP = 'timestamp',
}

export function isNumeric(attribute?: SelectableAttribute) {
  return isInteger(attribute) || isFloat(attribute)
}

export function isInteger(attribute?: SelectableAttribute) {
  const attr_data_type = attribute?.data_type?.toLowerCase()
  return attr_data_type === DataType.INT || attr_data_type === DataType.BIGINT
}

export function isFloat(attribute?: SelectableAttribute) {
  const attr_data_type = attribute?.data_type?.toLowerCase()
  return attr_data_type === DataType.DOUBLE
}

export function isDateOrTime(attribute?: SelectableAttribute) {
  const attr_data_type = attribute?.data_type?.toLowerCase()
  return attr_data_type === DataType.DATE || attr_data_type === DataType.TIMESTAMP
}

export function isString(attribute?: SelectableAttribute) {
  return attribute?.data_type?.toLowerCase() === DataType.STRING
}

export function isCategorical(attribute?: SelectableAttribute) {
  return !isNumeric(attribute) && !isDateOrTime(attribute)
}

export function dateToString(date: Date, short = false): string {
  if (date === null || date === undefined) {
    return ''
  }
  if (short) {
    return date.toLocaleString('en-SE', shortDateFormat)
  }
  return date.toLocaleString('en-SE', dateFormat)
}

export function dateStringDisplayFormat(dateString?: string, short = false): string {
  if (!dateString) {
    return ''
  }
  return dateToString(new Date(reformatDateString(dateString)), short)
}

export function reformatDateString(dateString: string): string {
  // if string does not contain Z, assume UTC and add Z
  if (!dateString.includes('Z')) {
    return `${dateString}Z`
  }
  return dateString
}

// Highlight the text that matches the search text
export function getHighlightedText(text?: string, searchText?: string) {
  if (!text) {
    return <></>
  }
  if (!searchText) {
    return <>{text}</>
  }
  const matches = match(text, searchText, {
    insideWords: true,
    findAllOccurrences: true,
  })
  const parts: { text: string; highlight: boolean }[] = parse(text, matches)
  return (
    <>
      {' '}
      {parts.map((part, index) => (
        <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
          {part.text}
        </span>
      ))}
    </>
  )
}

// This function is used for fuzzy search the Autocomplete component
export function fuzzyFilterOptions<T>(
  getLabel: (option: T) => string,
): (options: T[], { inputValue }: FilterOptionsState<any>) => T[] {
  return (options: T[], { inputValue }: FilterOptionsState<any>) => {
    if (!inputValue) {
      return options
    }
    const optionsForSearch = options.map(getLabel)
    const terms = inputValue.split(' ')
    const result = terms.reduceRight((accu, term) => matchSorter(accu, term), optionsForSearch)
    const resultOptions = result.map(r => options[optionsForSearch.indexOf(r)])
    return resultOptions
  }
}
