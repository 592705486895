import TableRowsOutlinedIcon from '@mui/icons-material/TableRowsOutlined'
import { Stack } from '@mui/material'
import { Card, CardContent, CardHeader, Divider, Typography } from '@northvolt/ui'
import type { Sample } from 'client/model'
import type React from 'react'
import { Link } from 'react-router-dom'
import { dateStringDisplayFormat } from './../Utils'

interface SampleCardProps {
  sample: Sample
}

const SampleCard: React.FC<SampleCardProps> = ({ sample }) => {
  const subHeader = `Created by ${sample?.author.full_name} on ${dateStringDisplayFormat(sample?.created_at?.toString(), true)}`
  return (
    <Card
      sx={{
        minHeight: 200,
        p: 1,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}>
      <Link to={`/atlas/samples/${sample.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
        <Stack direction='row' spacing={1} alignItems='top'>
          <TableRowsOutlinedIcon sx={{ mt: 1.25, ml: 1 }} style={{ fontSize: 30 }} />
          <CardHeader title={sample?.name} subheader={subHeader} sx={{ pt: 1, px: 0 }} />
        </Stack>
      </Link>
      <CardContent>
        <Divider>
          <Typography variant='overline'>Description</Typography>
        </Divider>
        <Typography variant='bodySmall'>{sample?.description}</Typography>
      </CardContent>
    </Card>
  )
}

export default SampleCard
