import ReadMoreIcon from '@mui/icons-material/ReadMore'
import { IconButton, ListItem, Stack, Typography } from '@northvolt/ui'
import type { SampleLight } from 'client/model'
import { Link } from 'react-router-dom'
import { getHighlightedText } from '../Utils'

interface SampleSelectItemProps {
  sample: SampleLight
  listItemProps?: any
  searchText?: string
}

const SampleSelectItem: React.FC<SampleSelectItemProps> = ({ sample, listItemProps, searchText }) => {
  return (
    <ListItem key={sample.id} divider {...listItemProps}>
      <Stack alignItems='left' sx={{ overflow: 'hidden', width: '100%' }}>
        <Typography title={sample.name} variant='subtitleMedium'>
          {getHighlightedText(sample.name, searchText)}
        </Typography>
        <Typography variant='captionSmall' color='text.secondary'>
          {getHighlightedText(sample?.description || '', searchText)}
        </Typography>
      </Stack>
      <IconButton edge='end' size='small' onClick={e => e.stopPropagation()} title='Read More'>
        <Link to={`/atlas/samples/${sample.id}`} style={{ color: 'inherit' }} target='_blank'>
          <ReadMoreIcon />
        </Link>
      </IconButton>
    </ListItem>
  )
}

export default SampleSelectItem
