import { Box, ListItem, Stack, Typography } from '@northvolt/ui'
import type { SampleLight } from 'client/model'
import type React from 'react'
import { Link } from 'react-router-dom'
import FilterChip from '../Filter/FilterChip'
import { dateStringDisplayFormat } from '../Utils'

interface SampleListItemProps {
  sample: SampleLight
  minimal?: boolean
}

const SampleListItem: React.FC<SampleListItemProps> = ({ sample, minimal }) => {
  return (
    <ListItem divider sx={{ p: 2, border: '1px solid #E0E0E0', borderRadius: 1 }} key={sample.id}>
      <Stack alignItems='left' sx={{ overflow: 'hidden', width: '100%' }}>
        <Link to={`/atlas/samples/${sample.id}`} style={{ color: 'inherit' }}>
          <Typography title={sample.name} variant='subtitleMedium'>
            <strong>{sample.name}</strong>
          </Typography>
        </Link>
        <Typography variant='captionSmall' color='text.secondary'>
          {sample.description}
        </Typography>
        {!minimal && (
          <>
            <Typography variant='captionSmall' color='text.secondary'>
              Created by {sample.author.full_name} on {dateStringDisplayFormat(sample?.created_at || '')}
            </Typography>
            <Box>
              {sample.filters?.map(filter => (
                <FilterChip key={filter.id} filter={filter} />
              ))}
            </Box>
          </>
        )}
      </Stack>
    </ListItem>
  )
}

export default SampleListItem
