import { IconButton } from '@mui/material'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  List,
  Loader,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from '@northvolt/ui'
import {
  useDeleteDataset,
  useFavoriteDataset,
  useIsDatasetFavorited,
  useIsDatasetOwnedByUser,
  useReadDataset,
  useUnfavoriteDataset,
} from 'client/atlas/atlas'
import React, { useEffect } from 'react'

import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import StarIcon from '@mui/icons-material/Star'
import StarBorderIcon from '@mui/icons-material/StarBorder'
import ErrorCard from 'components/ErrorCard'
import SampleListItem from 'components/Sample/SampleListItem'
import { dateStringDisplayFormat } from 'components/Utils'
import { Link, useNavigate, useParams } from 'react-router-dom'

import AttributeCollectionListItem from 'components/AttributeCollection/AttributeCollectionListItem'

export default function Page() {
  const { datasetId } = useParams()
  const idNumber = Number.parseInt(datasetId || '', 10) || 0

  const datasetLoader = useReadDataset(idNumber, { query: { retry: 1 } })
  const isUserOwner = useIsDatasetOwnedByUser(idNumber)

  // Favorites
  const isFavorite = useIsDatasetFavorited(idNumber)
  const favoriteMutation = useFavoriteDataset()
  const unfavoriteMutation = useUnfavoriteDataset()
  const navigate = useNavigate()

  useEffect(() => {
    if (!favoriteMutation.isLoading && !unfavoriteMutation.isLoading) {
      isFavorite.refetch()
    }
  }, [favoriteMutation.isLoading, unfavoriteMutation.isLoading])

  const favoriteButton = () => {
    if (isFavorite.isSuccess) {
      return (
        <IconButton
          size='small'
          onClick={() => {
            if (isFavorite.data.data) {
              unfavoriteMutation.mutate({ datasetId: idNumber })
            } else {
              favoriteMutation.mutate({ datasetId: idNumber })
            }
          }}>
          {isFavorite.data.data ? <StarIcon /> : <StarBorderIcon />}
        </IconButton>
      )
    }
    return (
      <IconButton size='small' disabled>
        <Loader type='circular' size='small' />
      </IconButton>
    )
  }

  // Deletion
  const datasetDeleteMutation = useDeleteDataset()
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = React.useState(false)

  const handleDatasetDeletion = () => {
    datasetDeleteMutation.mutate({ datasetId: idNumber })
  }

  useEffect(() => {
    if (datasetDeleteMutation.isSuccess) {
      setDeleteConfirmationOpen(false)
      navigate('/atlas/my-data')
    }
  }, [datasetDeleteMutation.isSuccess])

  const editButtons = () => {
    return (
      <>
        <Link to={`/atlas/datasets/${idNumber}/edit`} style={{ color: 'inherit' }}>
          <Tooltip title='Edit dataset'>
            <IconButton size='small'>
              <EditIcon />
            </IconButton>
          </Tooltip>
        </Link>
        <IconButton size='small' onClick={() => setDeleteConfirmationOpen(true)}>
          <Tooltip title='Delete dataset'>
            <DeleteIcon sx={{ color: 'error.main' }} />
          </Tooltip>
        </IconButton>
        <Dialog open={deleteConfirmationOpen} onClose={() => setDeleteConfirmationOpen(false)}>
          <DialogTitle>Confirm Deletion of Dataset</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure that you want to delete the dataset
              <br />
              <span style={{ fontWeight: 700 }}>{datasetLoader.data?.data.name}?</span>
              <br />
              This action cannot be undone.
              <br />( The attribute collections and samples will not be deleted)
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              onClick={() => setDeleteConfirmationOpen(false)}
              color='secondary'
              disabled={datasetDeleteMutation.isLoading}>
              Cancel
            </Button>
            <Button onClick={handleDatasetDeletion} color='error' disabled={datasetDeleteMutation.isLoading}>
              Confirm Deletion
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }

  if (datasetLoader.isLoading) {
    return (
      <Box display='flex' justifyContent='center' sx={{ py: 1, width: '100%' }}>
        <Loader type='linear' />
      </Box>
    )
  }
  if (datasetLoader.isError) {
    return (
      <ErrorCard
        title='Error'
        message={datasetLoader.error?.response?.data?.detail?.toString() || 'Attribute collection not found'}
      />
    )
  }
  const dataset = datasetLoader.data?.data
  return (
    <Grid container columns={{ xs: 6, md: 12 }} spacing={2}>
      <Grid xs={12}>
        <Paper sx={{ p: 4 }}>
          <Stack direction='row'>
            <Box flexGrow={1}>
              <Typography
                variant='headlineMedium'
                title={dataset?.name}
                sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                {dataset?.grain} Dataset / {dataset?.name}
              </Typography>
              <Typography variant='captionSmall' color='text.secondary'>
                Created by {dataset?.author.full_name} on {dateStringDisplayFormat(dataset?.created_at || '')}
              </Typography>
            </Box>
            <Box>
              {favoriteButton()}
              {isUserOwner?.data?.data === true && editButtons()}
            </Box>
          </Stack>
          <Stack direction='row' spacing={1} sx={{ mt: 2 }}>
            <Link
              to={{
                pathname: '/tools/plotter',
                search: `tab=0&datasetId=${dataset.id}`,
              }}>
              <Button size='small'>Plot Dataset</Button>
            </Link>
          </Stack>
        </Paper>
      </Grid>

      <Grid xs={12}>
        <Paper sx={{ px: 2, pb: 2, pt: 1, height: '100%' }}>
          <Divider>
            <Typography variant='overline'>Description</Typography>
          </Divider>
          <Typography variant='bodyMedium'>{dataset?.description}</Typography>
        </Paper>
      </Grid>

      <Grid xs={6}>
        <Paper sx={{ px: 2, pb: 2, pt: 1, height: '100%' }}>
          <Divider>
            <Typography variant='overline'>Attribute Collections</Typography>
          </Divider>
          <List sx={{ height: '40vh', overflow: 'auto', mx: 1 }}>
            {dataset?.attribute_collections?.map(attrCol => (
              <AttributeCollectionListItem key={attrCol.id} attributeCollection={attrCol} />
            ))}
          </List>
        </Paper>
      </Grid>

      <Grid xs={6}>
        <Paper sx={{ px: 2, pb: 2, pt: 1, height: '100%' }}>
          <Divider>
            <Typography variant='overline'>Samples</Typography>
          </Divider>
          <List sx={{ overflow: 'auto' }}>
            {dataset?.samples?.map(sample => (
              <SampleListItem key={sample.id} sample={sample} />
            ))}
          </List>
        </Paper>
      </Grid>
    </Grid>
  )
}
