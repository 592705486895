import { getIdToken } from '@northvolt/snowflake'
import { DateInt, Diff, Equal, Greater, NotNull, Null, Range, Smaller } from 'assets/icons'
import { type Dispatch, useEffect, useState } from 'react'
import {
  type Action,
  Actions,
  Attr,
  DataTypes,
  type Filter,
  type FilterComponentState,
  type FilterState,
  type Grain,
  type InputParams,
  type OperatorHandler,
  Operators,
  type Spec,
  type ValueTypes,
} from './Types'
import styles from './filter.module.scss'

// Define the type parameter T at the top level
type T = any

type NumberOrNumericInput = {
  input: {
    min?: number | string
    max?: number | string
    step?: number
    lang?: string
    type: string
    value?: number | string
    placeholder?: string
  }
  invalid: boolean
}
const multiValueSplitRegex = /[\n\t,]/

/**
 * NumericInput
 *
 * A component that allows the user to set a date or number.
 *
 * @param values The values to be filtered.
 * @param dispatch The dispatch function to update the state.
 * @param attr The attribute to be updated and the id of the input,
 * used to define its placeholder.
 * @param state The state of the parent component.
 * @param slot The slot of the input.
 * @param operator The operator to be displayed.
 *
 * @typeParam T - The type of the elements to be filtered.
 *
 * @returns A number input component.
 */
const NumericInput = <T,>({
  dispatch,
  slot,
  attr = Attr.VALUE,
  state,
  dataType,
  values,
}: {
  slot: number
  dispatch: Dispatch<Action<T>>
  attr?: Attr
  state: FilterComponentState<T>
  dataType: DataTypes
  values: ValueTypes
}) => {
  const { min_number, max_number, value, min_datetime, max_datetime } = values || {}

  const [innerState, setInnerState] = useState<NumberOrNumericInput>({
    input: {
      type: dataType,
      ...(dataType === DataTypes.number
        ? {
            step: 0.1,
            lang: 'se',
            ...(min_number ? { min: min_number } : {}),
            ...(max_number ? { max: max_number } : {}),
          }
        : {
            ...(min_datetime ? { min: min_datetime } : {}),
            ...(max_datetime ? { max: max_datetime } : {}),
          }),
      placeholder: value ? 'Fetching values..' : 'Set a value',
    },
    invalid: false,
  })

  const parseSpecs = async (spec: Spec) => {
    let min: number | string | undefined
    let max: number | string | undefined
    if (dataType === DataTypes.number) {
      min = spec.long_min ?? spec.double_min
      max = spec.long_max ?? spec.double_max
    }
    if (dataType === DataTypes.timestamp) {
      min = spec.datetime_min?.split('T')[0]
      max = spec.datetime_max?.split('T')[0]
    }
    if (spec.attribute.id) {
      const input = {
        ...innerState.input,
        ...(attr !== 'value' && {
          placeholder: `${attr.includes('min') ? min : (max ?? innerState.input.placeholder)}`,
        }),
        min,
        max,
      }

      setInnerState({
        ...innerState,
        input,
      })
    }
  }

  useEffect(() => {
    const spec = state.filters[slot].spec
    if (spec) {
      parseSpecs(spec)
    }
  }, [state.filters[slot].spec])

  const handleChange = (target: EventTarget & HTMLInputElement) => {
    const { min, max } = innerState.input || {}

    const isInvalid = !(
      target.value == undefined ||
      target.value == '' ||
      target.value < (min || 0) ||
      target.value > (max || 0)
    )

    setInnerState({
      ...innerState,
      invalid: !isInvalid,
      input: {
        ...innerState.input,
      },
    })

    const oppositeValue = attr.includes('min') ? attr.replace('min', 'max') : attr.replace('max', 'min')

    const verified =
      (isInvalid && attr === 'value') ||
      (`${oppositeValue}` in state.filters[slot].values &&
        state.filters[slot].values[`${oppositeValue}` as keyof ValueTypes] != undefined)

    dispatch({
      type: Actions.UPDATE_FILTER,
      payload: {
        slot,
        ...(dataType === DataTypes.timestamp
          ? {
              values: { [attr]: new Date(target.value).toISOString() },
            }
          : { values: { [attr]: target.value } }),
        isVerified: verified,
      },
    })

    dispatch({
      type: Actions.UPDATE_STATE,
      payload: {
        canDispatch: true,
      },
    })
  }

  return (
    <input
      value={value}
      {...{ ...innerState.input }}
      placeholder={innerState.input.placeholder ?? 'Fetching values...'}
      onChange={({ target }) => handleChange(target)}
      className={styles[`input${innerState.invalid ? 'Invalid' : ''}`]}
    />
  )
}

/**
 * RangeInput
 *
 * A component that allows the user to input a range of numbers or dates.
 *
 * @param slot The slot of the input.
 * @param dispatch The dispatch function to update the state.
 * @param state The specification of the attribute.
 * @param values The values to be filtered.
 * @param operator The operator to be displayed.
 *
 * @typeParam T - The type of the elements to be filtered.
 *
 * @returns A number range input component.
 */
const RangeInput = <T,>({
  slot,
  dispatch,
  state,
  values,
  dataType,
}: {
  slot: number
  dispatch: Dispatch<Action<T>>
  state: FilterComponentState<T>
  values: ValueTypes
  dataType: DataTypes
}) => {
  let min = Attr.MIN_NUMBER
  let max = Attr.MAX_NUMBER
  if (dataType === DataTypes.timestamp) {
    min = Attr.MIN_DATETIME
    max = Attr.MAX_DATETIME
  }

  return (
    <>
      <small>Between</small>
      <NumericInput {...{ slot, dispatch, state, values, dataType }} attr={min} />
      <small>and</small>
      <NumericInput {...{ slot, dispatch, state, values, dataType }} attr={max} />
    </>
  )
}

/**
 * BooleanInput
 *
 * A component that allows the user to input a boolean value.
 *
 * @param values The values to be filtered.
 * @param slot The slot of the input.
 * @param dispatch The dispatch function to update the state.
 * @param state The state of the parent component.
 *
 * @typeParam T - The type of the elements to be filtered.
 *
 * @returns A boolean input component.
 */
const BooleanInput = <T,>({
  values,
  slot,
  dispatch,
  state,
}: {
  values?: ValueTypes
  slot: number
  dispatch: Dispatch<Action<T>>
  state: FilterComponentState<T>
}) => {
  const [invalid, setInvalid] = useState(false)
  const filters = state.filters.slice(0)
  useEffect(() => {
    const fetchData = async () => {
      const data = await filters[slot].spec
      if (data) filters[slot].spec = data
      dispatch({
        type: Actions.UPDATE_FILTER,
        payload: { slot, attribute: data?.attribute as T },
      })
    }
    fetchData()
  }, [filters[slot].spec])

  const handleChange = (target: HTMLInputElement) => {
    if (!target.value) {
      setInvalid(true)
    }
    dispatch({
      type: Actions.UPDATE_FILTER,
      payload: {
        slot,
        values: { value: target.value },
        isVerified: true,
      },
    })
    dispatch({
      type: Actions.UPDATE_STATE,
      payload: {
        canDispatch: true,
      },
    })
  }

  return (
    <>
      <input
        required
        multiple
        list={`values-reference-${slot}`}
        type={'email'}
        placeholder='Enter a value'
        defaultValue={values?.value ? values.value : undefined}
        className={styles[`input${invalid ? 'Invalid' : ''}`]}
        onFocus={() => setInvalid(false)}
        onBlur={({ currentTarget }) => handleChange(currentTarget)}
        onChange={({ target }) => handleChange(target)}
      />
      <datalist id={`values-reference-${slot}`}>
        <option value='True'>True</option>
        <option value='False'>False</option>
      </datalist>
    </>
  )
}

/**
 * TextInput
 *
 * A component that allows the user to input a text value.
 *
 * @param values The values to be filtered.
 * @param slot The slot of the input.
 * @param dispatch The dispatch function to update the state.
 * @param state The state of the parent component.
 *
 * @typeParam T - The type of the elements to be filtered.
 *
 * @returns A text input component.
 */
const TextInput = <T,>({
  values,
  slot,
  dispatch,
  state,
}: {
  values?: ValueTypes
  slot: number
  dispatch: Dispatch<Action<T>>
  state: FilterComponentState<T>
}) => {
  const filters = state.filters.slice(0)
  const [placeholder, setPlaceholder] = useState('Fetching possible values...')
  const [invalid, setInvalid] = useState(false)
  const [valuesSet, setValuesSet] = useState<string[]>([])

  useEffect(() => {
    const fetchData = async () => {
      const data = filters[slot].spec
      if (data?.string_distinct_values?.length) {
        setValuesSet(data.string_distinct_values)
        filters[slot].attribute = data.attribute as T
      }
      setPlaceholder('Enter a value')
    }
    fetchData()
  }, [filters[slot].spec])

  const handleChange = (currentTarget: EventTarget & HTMLInputElement) => {
    if (!currentTarget.value) {
      setInvalid(true)
    }

    const value = currentTarget.value.match(multiValueSplitRegex)
      ? currentTarget.value.split(multiValueSplitRegex)
      : currentTarget.value

    dispatch({
      type: Actions.UPDATE_FILTER,
      payload: {
        slot,
        values: {
          ...(Array.isArray(value)
            ? {
                value: undefined,
                multiple: true,
                multiple_values: value.map(el => ({ value: el })),
              }
            : {
                value: value,
                multiple_values: undefined,
              }),
        },
        isVerified: true,
      },
    })

    dispatch({
      type: Actions.UPDATE_STATE,
      payload: {
        canDispatch: true,
      },
    })
  }

  return (
    <div className={styles.column}>
      <input
        required
        multiple
        spellCheck='false'
        list={`values-reference-${slot}`}
        type={'email'} // This is a hack to allow the datalist to implement multiple values
        defaultValue={values?.value ? values.value : undefined}
        {...{ placeholder }}
        className={styles[`input${invalid ? 'Invalid' : ''}`]}
        onFocus={() => setInvalid(false)}
        onBlur={({ currentTarget }) => handleChange(currentTarget)}
      />
      <span className={styles.subtext}>Split values with comma</span>
      <datalist id={`values-reference-${slot}`}>
        {valuesSet?.sort().map(el => (
          <option key={`${slot}-${el}-value`} value={el} />
        ))}
      </datalist>
    </div>
  )
}

/**
 * Operators
 *
 * The various operators the interface can display.
 * It includes the operators to be displayed and the icon to be displayed.
 * It also includes the component to be displayed.
 *
 * @typeParam T - The type of the elements to be filtered.
 *
 * @returns The operators to be displayed.
 */
const operatorsMap: { [key in Operators]: OperatorHandler<T> } = {
  [Operators.DATETIME_RANGE]: {
    value: Operators.DATETIME_RANGE,
    label: 'Date range',
    icon: <DateInt />,
    component: RangeInput,
  },
  [Operators.NUMBER_RANGE]: {
    value: Operators.NUMBER_RANGE,
    label: 'Range',
    icon: <Range />,
    component: RangeInput,
  },
  [Operators.GREATER_THAN]: {
    value: Operators.GREATER_THAN,
    label: 'Greater than',
    icon: <Greater />,
    component: NumericInput,
  },
  [Operators.LESSER_THAN]: {
    value: Operators.LESSER_THAN,
    label: 'Lesser than',
    icon: <Smaller />,
    component: NumericInput,
  },
  [Operators.EQUAL]: {
    value: Operators.EQUAL,
    label: 'Equal to',
    icon: <Equal />,
  },
  [Operators.NOT_EQUAL]: {
    value: Operators.NOT_EQUAL,
    label: 'Different from',
    icon: <Diff />,
  },
  [Operators.NOT_NULL]: {
    value: Operators.NOT_NULL,
    label: 'Exists',
    icon: <NotNull />,
    component: () => <></>,
  },
  [Operators.NULL]: {
    value: Operators.NULL,
    label: `Doesn't exist`,
    icon: <Null />,
    component: () => <></>,
  },
}

/**
 * isNullishComparison
 *
 * A function that checks if a given operator checks for nullification.
 * It returns a boolean indicating if the operator is or not part of the nullish checks.
 *
 * @param type The unknown operator.
 *
 * @returns A boolean indicating if the operator is or not part of the nullish checks.
 */
const isNullishComparison = (type: Operators) => [Operators.NULL, Operators.NOT_NULL].includes(type)

/**
 * numberOperators
 *
 * The operators to be displayed when the data type is number.
 */
const numberOperators = [
  operatorsMap[Operators.NUMBER_RANGE],
  operatorsMap[Operators.GREATER_THAN],
  operatorsMap[Operators.LESSER_THAN],
]

/**
 * dateOperators
 *
 * The operators to be displayed when the data type is date.
 */
const dateOperators = [operatorsMap[Operators.DATETIME_RANGE]]

/**
 * defaultOps
 *
 * The default operators to be displayed generically,
 * for all data types.
 */
const defaultOps = [
  operatorsMap[Operators.EQUAL],
  operatorsMap[Operators.NOT_EQUAL],
  operatorsMap[Operators.NULL],
  operatorsMap[Operators.NOT_NULL],
]

/**
 * getOperators
 *
 * A function that returns the operators to be displayed.
 * It includes the default operators and the extra operations.
 * It also includes a check to display the operators in the
 * correct component.
 *
 * @param Component The component to be displayed.
 * @param extraOperations The extra operations to be displayed.
 *
 * @typeParam T - The type of the elements to be filtered.
 * @returns The operators to be displayed.
 */
const getOperators = <T,>(Component: InputParams<T>, extraOperations?: OperatorHandler<T>[]) =>
  [...(extraOperations ? extraOperations : []), ...defaultOps].map(el =>
    [Operators.EQUAL, Operators.NOT_EQUAL].includes(el.value) ? { ...el, Component } : el,
  )

/**
 * dataTypesMap
 *
 * The various data types the interface can display.
 */
const dataTypesMap = {
  [DataTypes.number]: {
    type: 'number',
    component: NumericInput,
    operators: getOperators(NumericInput, numberOperators),
  },
  [DataTypes.boolean]: {
    type: 'boolean',
    component: BooleanInput,
    operators: getOperators(BooleanInput),
  },
  [DataTypes.timestamp]: {
    type: 'date',
    component: NumericInput,
    operators: getOperators(NumericInput, dateOperators),
  },
  [DataTypes.string]: {
    type: 'text',
    component: TextInput,
    operators: [...defaultOps],
  },
}

/**
 * headers
 *
 * The headers to be used in the fetch request.
 * It includes the authorization token and the content type.
 *
 * @returns The headers to be used in the fetch request.
 *
 */
const headers = {
  'Authorization': `Bearer ${getIdToken()}`,
  'Content-Type': 'application/json',
}

/**
 * search
 *
 * Fetch the attributes that match the search term.
 *
 * @param term - The term to be searched.
 * @param grain - The grain of the attribute.
 *
 * @returns {Promise<Object>} - The promise of the fetch request, returning an object with the search results.
 */
const search = async (term: string, grain: Grain) =>
  term && grain
    ? await fetch(
        encodeURI(
          `${
            import.meta.env.VITE_API_URI
          }api/atlas/attributes/text_search?text=${term}&grain=${grain}&limit=25&offset=0`,
        ),
        {
          headers,
          method: 'POST',
        },
      ).then(res => {
        return res.json()
      })
    : null

/**
 * labelBuilder
 *
 * Against all the odds, it actually builds a subtitle.
 *
 * @param type - The type of the attribute.
 * @param grain - The grain of the attribute.
 *
 * @returns {String} - The subtitle contained in the data-list attribute.
 */
const labelBuilder = (type: string, grain: string) => `A ${type} attribute from a ${grain}`

/**
 * toState
 *
 * A function that converts the filters to a state.
 *
 * @param f - The filters to be converted.
 *
 * @typeParam T - The type of the elements to be filtered.
 *
 * @returns {Array} - The state of the filters.
 *
 */
const toState = (f: Filter<T>[]): FilterState<T>[] =>
  f.map((f: Filter<T>) => ({
    attribute: f.attribute,
    attribute_id: f.attribute_id,
    id: f.id,
    operator: f.type,
    isVerified: true,
    invalid: false,
    spec: {
      attribute: f.attribute,
      id: f.attribute_id,
      long_max: f.max_number,
      long_min: f.min_number,
      double_max: f.max_number,
      double_min: f.min_number,
      datetime_max: f.max_datetime,
      datetime_min: f.min_datetime,
      string_distinct_values: f.multiple_values ? f.multiple_values.map(el => el.value) : [],
    },
    values: {
      max_datetime: f.max_datetime,
      max_number: f.max_number,
      min_datetime: f.min_datetime,
      min_number: f.min_number,
      multiple: f.multiple,
      multiple_values: f.multiple_values,
      value: f.value,
    },
  }))

/**
 * toFilter
 *
 * A function that converts the state to a filter.
 * It filters the state to remove the invalid and unverified filters.
 *
 * @param f - The state of the filters to be converted.
 *
 * @typeParam T - The type of the elements to be filtered.
 *
 * @returns {Array} - The filters to be used.
 */
const toFilter = (f: FilterState<T>[]): Filter<T>[] =>
  f.reduce((acc, f: FilterState<T>) => {
    if (!f.isAttributeInvalid && f.isVerified && f.attribute?.['id' as keyof typeof f.attribute]) {
      acc.push({
        ...(f.id ? { id: f.id } : {}),
        attribute: f.attribute,
        attribute_id: f.attribute?.['id' as keyof typeof f.attribute],
        type: f.operator,
        ...(f.id ? { id: f.id } : {}),
        ...f.values,
      })
    }
    return acc
  }, [] as Filter<T>[])

/**
 * getTypedAssets
 *
 * The function to get the dynamic assets needed to implement
 * the selection.
 *
 * @param {T | Attribute | String} attribute - The attribute to be checked.
 *
 * @typeParam {T} - The type of the elements to be filtered.
 * @typeParam {Attribute} - The attribute to be checked.
 * @typeParam {String} - The string to be checked.
 *
 * @returns {Object} - The handler and the data type of the attribute.
 */
const getTypedAssets = <T,>(attribute: T | string) => {
  if (!attribute || typeof attribute === 'string') return undefined

  const attributeDataType = attribute?.['data_type' as keyof typeof attribute] as keyof typeof DataTypes
  const dataType = DataTypes[attributeDataType]
  const handler = dataTypesMap[dataType]
  return { handler, dataType }
}

export {
  Actions,
  BooleanInput,
  dataTypesMap,
  getTypedAssets,
  isNullishComparison,
  labelBuilder,
  NumericInput,
  Operators,
  operatorsMap,
  RangeInput,
  search,
  TextInput,
  toFilter,
  toState,
}
