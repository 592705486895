import { Check, Sum } from 'assets/icons'
import { type ChangeEvent, type Reducer, useEffect, useReducer, useRef } from 'react'
import {
  Actions,
  Operators,
  TextInput,
  getTypedAssets,
  isNullishComparison,
  labelBuilder,
  operatorsMap,
  toFilter,
  toState,
} from './Utils'

import { getIdToken } from '@northvolt/snowflake'
import debounce from 'helpers/debounce'
import {
  type Action,
  DataTypes,
  type FilterComponentState,
  type FilterParams,
  type FilterState,
  type Spec,
  TypesOfValues,
} from './Types'
import styles from './filter.module.scss'

/**
 * Filter
 *
 * Component to execute filters on a list of elements, usually attributes of a cell.
 *
 * @typedef {Object} FilterParams
 * @param {Function} set - Function to return the selected elements
 * to the container component.
 * @param {Array} elements - List of elements to be filtered.
 * Defaults to an empty array and searches for elements to render the
 * suggestions list.
 *
 * @param {String} targetKey - Key to be used as the unique
 * identifier, in case of the element being an object.
 * Default is 'unique_name' as we usually handle attributes of a cell here.
 *
 * @param {Boolean} disabled - If the component should appear as disabled.
 * It's useful in case you want to allow the user to select only from a restricted
 * group of elements that is set during their actions.
 * It defaults to false.
 *
 * @typedParam {T} - The type of the elements to be filtered.
 *
 * @returns {Component} - The Filter component.
 *
 * @example
 * <Filter
 *  set={setFilters}
 * elements={elements}
 * targetKey='unique_name'
 * disabled={false}
 * />
 *
 */
export default <T,>({
  set,
  targetKey = 'unique_name' as keyof T,
  elements = [] as T[],
  disabled = false,
  grain,
  initial,
}: FilterParams<T>) => {
  const buttonRef = useRef<Record<number, HTMLButtonElement | null>>({})
  const inputRef = useRef<Record<number, HTMLInputElement | null>>({})
  const datalistRef = useRef<Record<number, HTMLDataListElement | null>>({})

  /**
   * filterReducer
   *
   * The reducer function to handle the state of the filter component.
   *
   * @param {FilterComponentState} state - The current state of the component.
   * @param {Action} action - The action to be dispatched.
   *
   * @typeParam {T} - The type of the elements to be filtered.
   *
   * @returns {FilterComponentState} - The new state of the component.
   */
  const filterReducer = <T,>(state: FilterComponentState<T>, action: Action<T>): FilterComponentState<T> => {
    const { type, payload } = action
    switch (type) {
      case Actions.UPDATE_MULTIPLE: {
        if (!payload) return state
        return {
          ...state,
          ...payload,
        }
      }
      case Actions.UPDATE_FILTER: {
        if (payload == undefined || payload.slot == undefined) return state
        const filters = state.filters.slice(0)
        filters[payload.slot] = {
          ...filters[payload.slot],
          ...payload,
          ...(payload.spec
            ? {
                attribute: (payload.spec.attribute as T) ?? payload.attribute ?? state.filters[payload.slot].attribute,
                spec: payload.spec,
                isLoading: false,
              }
            : {
                attribute: payload.attribute ?? state.filters[payload.slot].attribute,
                spec: payload.spec,
              }),
          values: {
            ...filters[payload.slot].values,
            ...payload.values,
          },
        }
        return {
          ...state,
          filters,
        }
      }
      case Actions.UPDATE_STATE: {
        if (payload == undefined) return state
        return {
          ...state,
          ...payload,
        }
      }

      default:
        return state
    }
  }

  /**
   * useReducer
   *
   * The hook to manage the state of the filter component.
   * It uses the filterReducer to handle the state,
   * initializes the state with the elements passed as props, and
   * also initializes the state with the filters passed as props.
   *
   * @param {Reducer} filterReducer - The reducer function to handle the state.
   * @param {FilterComponentState} - The initial state of the component.
   * @param {Function} - The function to initialize the state with the elements.
   *
   * @typeParam {T} - The type of the elements to be filtered.
   *
   * @returns {Array} - The state and the dispatcher function.
   */
  const [state, dispatch] = useReducer<Reducer<FilterComponentState<T>, Action<T>>>(filterReducer, {
    filters: initial?.length ? toState(initial) : [],
    activeOptions: -1,
    activeModal: -1,
    activeFilter: -1,
    canDispatch: false,
    elements: [] as T[],
  })

  /**
   * handleClick
   *
   * The function to handle the click outside the modal.
   *
   * @param {MouseEvent} e - The event of the click.
   */
  const handleClick = (e: MouseEvent) => {
    if (state.activeModal == undefined || state.activeOptions == undefined) return
    e.stopPropagation()
    if (!buttonRef.current[state.activeModal]?.contains(e.target as Node)) {
      dispatch({
        type: Actions.UPDATE_STATE,
        payload: { activeModal: -1 },
      })
    }
    if (!datalistRef.current[state.activeOptions]?.contains(e.target as Node)) {
      dispatch({
        type: Actions.UPDATE_STATE,
        payload: { activeOptions: -1 },
      })
    }
  }

  /**
   * defaults
   *
   * The initial filter state.
   * It sets the default values for the filters.
   */
  const filterDefaults = {
    isVerified: false,
    attribute: undefined,
    operator: Operators.EQUAL,
    spec: undefined,
    isAttributeInvalid: false,
  }

  /**
   * AttributeInputChangeHandler
   *
   * The function to handle the input of the attribute.
   * It fetches the statistics of the attribute and updates the state accordingly.
   *
   * @param {EventTarget & HTMLInputElement} target - The target of the event.
   * @param {Number} slot - The position of the filter in the state.
   *
   * @typeParam {T} - The type of the elements to be filtered.
   *
   * @returns {Void} - It returns nothing.
   */
  const AttributeInputChangeHandler = async ({ target }: { target: EventTarget & HTMLInputElement }, slot: number) => {
    const scope = elements.length ? elements : state.elements
    const attribute = scope.find(el => el[targetKey] === target.value)

    if (!attribute || !elements.length) {
      doSearch(target.value, grain, slot)
      dispatch({
        type: Actions.UPDATE_STATE,
        payload: {
          activeOptions: slot,
        },
      })
      dispatch({
        type: Actions.UPDATE_FILTER,
        payload: {
          slot,
          attribute: undefined,
          isAttributeInvalid: false,
        },
      })
      return
    }

    setAValue(attribute, slot)
  }

  const getDatalistClassName = (slot: number) => {
    const input = inputRef.current[slot]
    return styles[
      `datalistArea${
        !!input && input.offsetLeft !== undefined && input.offsetLeft >= window.innerWidth / 2 ? 'Right' : 'Left'
      }`
    ]
  }

  /**
   * setAValue
   *
   * The function to set the value of the attribute.
   *
   * @param {EventTarget & HTMLInputElement} target - The target of the event.
   * @param {Number} slot - The position of the filter in the state.
   *
   * @typeParam {T} - The type of the elements to be filtered.
   */
  const setAValue = (attribute: T, slot: number) => {
    const operator: Operators = Operators.EQUAL
    const { handler } = getTypedAssets(attribute) || {}
    const id = attribute['id' as keyof typeof attribute] as string

    if (!id || !attribute) {
      dispatch({
        type: Actions.UPDATE_FILTER,
        payload: {
          slot,
          attribute: undefined,
          isAttributeInvalid: true,
        },
      })
      return
    }

    specsLoader(`${id}`, slot)

    if (inputRef.current[slot]) {
      inputRef.current[slot]!.value = String(attribute[targetKey as keyof T])
    }
    dispatch({
      type: Actions.UPDATE_FILTER,
      payload: {
        attribute,
        operator: handler && Object.hasOwn(handler, 'operators') ? handler.operators[0].value : Operators.EQUAL,
        isVerified: isNullishComparison(operator) ?? false,
        isAttributeInvalid: false,
        spec: undefined,
        isLoading: true,
        slot,
      },
    })
    dispatch({
      type: Actions.UPDATE_STATE,
      payload: {
        activeModal: -1,
        activeOptions: -1,
        activeFilter: slot,
      },
    })
  }

  /**
   * doSearch
   *
   * The function to search for the attribute.
   * It fetches the attributes from the API and updates the state accordingly.
   *
   * @param {String} value - The value to be searched.
   * @param {String} grain - The grain of the attribute.
   * @param {Number} slot - The position of the filter in the state.
   *
   * @typeParam {T} - The type of the elements to be filtered.
   *
   * @returns {Promise} - The promise of the search.
   *
   * @example
   * doSearch('value', 'grain', 0)
   *
   */
  const doSearch = async (value: string, grain: string, slot: number) => {
    const res = await fetch(
      encodeURI(
        `${
          import.meta.env.VITE_API_URI
        }api/atlas/attributes/text_search?text=${value}&grain=${grain}&limit=25&offset=0`,
      ),
      {
        headers: {
          'Authorization': `Bearer ${getIdToken()}`,
          'Content-Type': 'application/json',
        },
        method: 'POST',
      },
    )

    const filters = state.filters.slice(0)
    filters[slot] = { ...filterDefaults, isAttributeInvalid: false, values: {} }

    const body = await res.json()
    if (body) {
      dispatch({
        type: Actions.UPDATE_STATE,
        payload: {
          filters,
          activeOptions: slot,
          elements: body.items || ([] as T[]),
          search: undefined,
        },
      })
    }
  }

  /**
   * specsLoader
   *
   * The function to load the statistics of the attribute.
   * It fetches the statistics from the API and updates the state accordingly.
   *
   * @param {String} id - The id of the attribute.
   * @param {Number} slot - The position of the filter in the state.
   * @param {FilterState} filter - The filter to be updated.
   *
   * @typeParam {T} - The type of the elements to be filtered.
   *
   * @returns {Promise} - The promise of the statistics.
   *
   * @example
   * specsLoader('id', 0, filter)
   */
  const specsLoader = async (id: string, slot: number) => {
    const res = await fetch(`${import.meta.env.VITE_API_URI}api/wombat/attribute_statistics/${id}`, {
      headers: {
        'Authorization': `Bearer ${getIdToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'GET',
    })
    const body: Spec = await res.json()
    if (body) {
      dispatch({
        type: Actions.UPDATE_FILTER,
        payload: {
          slot,
          spec: body,
          attribute: body.attribute as T,
          isAttributeInvalid: false,
          isLoading: false,
        },
      })
      dispatch({
        type: Actions.UPDATE_STATE,
        payload: {
          activeModal: -1,
          activeOptions: -1,
          activeFilter: -1,
        },
      })
    }
  }

  /**
   * newFilter
   *
   * The function to add a new filter to the state and signalize the parent should halt the form.
   * It updates the state and dispatches the data to the parent component.
   *
   * @returns {Void} - It returns nothing.
   */
  const newFilter = () => {
    set([...state.filters, {}])
    dispatch({
      type: Actions.UPDATE_STATE,
      payload: {
        filters: [
          ...state.filters,
          {
            ...filterDefaults,
            slot: state.filters.length,
            values: new TypesOfValues(),
          },
        ],
      },
    })
    inputRef.current[state.filters.length]?.focus()
  }

  /**
   * useEffect
   * The effect to handle the click outside the modal.
   * It listens to the click event and closes the modal if the click
   * is outside the modal.
   *
   * @param {Function} handleClick - The function to handle the click.
   *
   * @returns {Void} - It returns nothing.
   */
  useEffect(() => {
    document.addEventListener('mousedown', handleClick)
    return () => document.removeEventListener('mousedown', handleClick)
  }, [state.activeModal, state.activeOptions])

  /**
   * useEffect hook - The effect to handle the filters.
   * It updates the filters in the state and dispatches the data
   * to the parent component.
   *
   * @returns {Void} - returns nada, zero, niente.
   */
  useEffect(() => {
    if (!state.canDispatch) return

    set(toFilter(state.filters))
    dispatch({
      type: Actions.UPDATE_STATE,
      payload: { canDispatch: false },
    })
  }, [state.canDispatch])

  /**
   * useEffect hook - The effect to handle the elements.
   * It updates the elements in the state.
   *
   * @returns {Void} - I'll leave as exercise to the reader.
   */
  useEffect(() => {
    if (elements.length && elements !== state.elements) {
      dispatch({
        type: Actions.UPDATE_STATE,
        payload: { elements },
      })
    }
  }, [elements])

  /**
   * onFocus
   *
   * The function to handle the focus of the input.
   * It updates the state and dispatches the data to the parent component.
   *
   * @param {Number} slot - The position of the filter in the state.
   *
   * @returns {Void} - It returns nothing
   *
   */
  const onFocus = (slot: number) => {
    dispatch({
      type: Actions.UPDATE_STATE,
      payload: {
        activeFilter: slot,
        activeOptions: -1,
      },
    })

    dispatch({
      type: Actions.UPDATE_FILTER,
      payload: {
        slot,
        isAttributeInvalid: false,
      },
    })
  }

  // here we go.
  return (
    <div className={styles.container}>
      {[...(state.filters || [])].map(<T,>(filter: FilterState<T>, i: number) => {
        const {
          operator,
          attribute,
          isLoading,
          isVerified = false,
          isAttributeInvalid = false,
          slot = i,
          values,
          spec,
        } = filter

        const attr = attribute?.[targetKey as unknown as keyof typeof attribute]
        return (
          <div key={`${slot}-filter`} className={styles.filterArea} data-title='Filters'>
            <div className={styles.row}>
              <input
                required
                ref={el => (inputRef.current[slot || i] = el)}
                type='text'
                placeholder='Select an option'
                list={`list-${slot}`}
                className={styles[`input${spec || !isAttributeInvalid ? '' : 'Invalid'}`]}
                disabled={disabled}
                defaultValue={attr ? String(attr) : undefined}
                spellCheck='false'
                // onBlur={({ target }) => {
                //   dispatch({
                //     type: Actions.UPDATE_FILTER,
                //     payload: {
                //       isAttributeInvalid: !attribute
                //     }
                //   })
                // }}
                onFocus={() => onFocus(slot)}
                onChange={debounce((e: ChangeEvent<HTMLInputElement>) => AttributeInputChangeHandler(e, slot), 300)}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    e.preventDefault()
                    setAValue(state.elements[0], slot)
                  }
                }}
              />
              <div className={getDatalistClassName(slot)}>
                <datalist ref={el => (datalistRef.current[slot || i] = el)} className={styles.datalist}>
                  {state.elements?.map(el => {
                    const value = String(el[targetKey as keyof typeof el])
                    const isSelected = String(attribute?.[targetKey as keyof typeof attribute]).includes(value)
                    const dataT = el ? DataTypes[el?.['data_type' as keyof typeof el] as keyof typeof DataTypes] : ''
                    const grain = el?.['grain' as keyof typeof el] as string
                    const label = grain && dataT ? labelBuilder(dataT as string, grain) : ''
                    const input = inputRef.current[slot]?.value || ''
                    return state.activeOptions == slot ? (
                      <div
                        key={`${el[targetKey as keyof typeof el]}_option-${slot}`}
                        data-element={el}
                        className={isSelected ? styles.optionsSelected : styles.option}
                        onClick={() => setAValue(el, slot)}>
                        <p>
                          {value
                            .split('')
                            .map((letter, index) =>
                              input.toString().includes(letter.toLowerCase()) ? (
                                <b key={index}>{letter}</b>
                              ) : (
                                <span key={index}>{letter}</span>
                              ),
                            )}
                        </p>
                        <span> {label} </span>
                      </div>
                    ) : null
                  })}
                </datalist>
              </div>
              <button
                ref={el => (buttonRef.current[slot || i] = el)}
                disabled={!attribute}
                className={styles.operatorsButton}
                onClick={() =>
                  state.activeModal !== slot
                    ? dispatch({
                        type: Actions.UPDATE_STATE,
                        payload: {
                          activeFilter: slot,
                          activeModal: slot,
                        },
                      })
                    : null
                }>
                {operatorsMap[operator || Operators.EQUAL].icon}
                {state.activeModal === slot &&
                  (() => {
                    const { handler } = getTypedAssets(attribute) || {}
                    return (
                      <div className={styles.operators}>
                        {handler?.operators.map(({ label, icon, value }, i) => {
                          return (
                            <div
                              key={`${slot}-${i}_button`}
                              className={styles.operation}
                              onClick={() => {
                                const isVerified = isNullishComparison(value)
                                dispatch({
                                  type: Actions.UPDATE_STATE,
                                  payload: {
                                    filters: state.filters.map((f, i) =>
                                      i === slot
                                        ? {
                                            ...f,
                                            operator: value,
                                            isVerified,
                                          }
                                        : f,
                                    ),
                                    activeFilter: slot,
                                    activeModal: -1,
                                    canDispatch: isVerified,
                                  },
                                })
                              }}>
                              {icon}
                              {label}
                            </div>
                          )
                        })}
                      </div>
                    )
                  })()}
              </button>
              {operator &&
                attribute &&
                (() => {
                  if (!state.filters[slot]?.attribute) return null
                  const { handler, dataType } = getTypedAssets(state.filters[slot].attribute) || {}
                  const Component =
                    'component' in operatorsMap[operator]
                      ? operatorsMap[operator].component
                      : handler?.component || TextInput

                  return (
                    <>
                      {dataType && Component ? (
                        <Component
                          {...{
                            state,
                            slot,
                            dispatch,
                            dataType,
                            values,
                            operator,
                          }}
                        />
                      ) : null}
                      {isVerified && <Check />}
                      {isLoading && <div className={styles.loading} />}
                    </>
                  )
                })()}
              <div
                className={styles.close}
                onClick={() =>
                  dispatch({
                    type: Actions.UPDATE_STATE,
                    payload: {
                      filters: state.filters.filter((_, j) => j !== slot),
                    },
                  })
                }>
                +
              </div>
            </div>
          </div>
        )
      })}
      {!initial?.length && (
        <div className={styles.addButton}>
          <button type='button' title='add filter' className={styles.addFilter} disabled={disabled} onClick={newFilter}>
            <Sum />
          </button>
        </div>
      )}
    </div>
  )
}
