import ScatterPlotIcon from '@mui/icons-material/ScatterPlot'
import { Chip } from '@northvolt/ui'
import type { SampleLight } from 'client/model'
import type React from 'react'
import { Link } from 'react-router-dom'

interface SampleChipProps {
  sample: SampleLight
  chipProps?: any
  clickable?: boolean
}

const SampleChip: React.FC<SampleChipProps> = ({ sample, chipProps, clickable }) => {
  const { key, ...props } = chipProps ?? {}
  const chip = (
    <Chip
      label={sample?.name}
      title={`Sample: ${sample?.name}`}
      size='small'
      sx={{ m: 0.25 }}
      color='secondary'
      variant='outlined'
      icon={<ScatterPlotIcon />}
      {...props}
    />
  )

  if (clickable) {
    return (
      <Link to={`/atlas/samples/${sample.id}`} style={{ color: 'inherit' }}>
        {chip}
      </Link>
    )
  } else {
    return chip
  }
}

export default SampleChip
