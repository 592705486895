import LiveHelpOutlinedIcon from '@mui/icons-material/LiveHelpOutlined'
import MenuIcon from '@mui/icons-material/Menu'
import { AppBar, IconButton, Menu, MenuItem, Toolbar, Typography, useTheme } from '@mui/material'
import { SignOut, getUser } from '@northvolt/snowflake'
import { Avatar, Box, Stack } from '@northvolt/ui'
import { motion } from 'framer-motion'
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

interface VeronaTopBarProps {
  menuOpen: boolean
  setMenuOpen: (open: boolean) => void
}

const VeronaTopBar: React.FC<VeronaTopBarProps> = ({ menuOpen, setMenuOpen }) => {
  const user = getUser()
  const navigate = useNavigate()

  const theme = useTheme()
  const handleDrawerToggle = () => {
    setMenuOpen(!menuOpen)
  }

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleUserMenuClose = () => {
    setAnchorEl(null)
  }

  return (
    <AppBar position='fixed' sx={{ zIndex: theme => theme.zIndex.drawer + 1 }}>
      <Toolbar disableGutters sx={{ height: { xs: 56, sm: 56 }, minHeight: { xs: 56, sm: 56 } }}>
        <IconButton color='inherit' edge='start' onClick={handleDrawerToggle} sx={{ ml: 1 }}>
          <MenuIcon />
        </IconButton>
        <Link to='/' style={{ textDecoration: 'none', color: 'inherit', padding: 0 }}>
          <Stack sx={{ ml: 2 }}>
            <motion.div
              initial={{ opacity: 0, scale: 1.4 }}
              animate={{ scale: 1, opacity: 1, from: 0, to: 1 }}
              transition={{ ease: 'easeIn' }}>
              <Typography variant='subtitleMedium' sx={{ fontSize: 24, letterSpacing: 14, marginTop: '-5px' }}>
                verona
              </Typography>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, translateX: -30 }}
              animate={{ translateX: 0, opacity: 1, from: 0, to: 1 }}
              transition={{ delay: 0.4, ease: 'easeOut' }}
              style={{ marginTop: '-15px' }}>
              <Typography variant='captionSmall' sx={{ ml: 0.5, mt: -1, mb: 1 }}>
                Powered by GigaWattson
              </Typography>
            </motion.div>
          </Stack>
        </Link>

        <Box sx={{ flexGrow: 1 }} />
        <Typography
          variant='captionSmall'
          sx={{ mr: 1, display: { xs: 'none', sm: 'block' } }}
          color={theme.palette.secondary.light}>
          version 0.1.0
        </Typography>
        <Link to='/help' style={{ textDecoration: 'none' }}>
          <IconButton>
            <LiveHelpOutlinedIcon style={{ color: theme.palette.secondary.light }} />
          </IconButton>
        </Link>
        <IconButton size='large' onClick={handleUserMenu} color='inherit'>
          <Avatar
            variant='rounded'
            sx={{
              width: '35px',
              height: '35px',
            }}>
            <Typography variant='bodySmall'>{user?.initials || 'You'}</Typography>
          </Avatar>
        </IconButton>
        <Menu
          id='menu-appbar'
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          keepMounted
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={Boolean(anchorEl)}
          onClose={handleUserMenuClose}>
          <MenuItem
            onClick={() => {
              SignOut()
              navigate('/')
            }}>
            Logout
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  )
}

export default VeronaTopBar
