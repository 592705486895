/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * GigaWattson
 * OpenAPI spec version: 0.0.1
 */

export type FilterType = typeof FilterType[keyof typeof FilterType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FilterType = {
  equal: 'equal',
  not_equal: 'not_equal',
  number_range: 'number_range',
  datetime_range: 'datetime_range',
  null: 'null',
  not_null: 'not_null',
} as const;
