import type { Attribute, AttributeCollection, DataLoadingParamsCreate, Sample } from 'client/model'

type PageParams = {
  targetName: string
  sampleNames: string[]
  attributeCollectionNames: string[]
}

type CorrelationState = {
  data_loading_params: DataLoadingParamsCreate
  target_attribute: Attribute
  attributeCollections: AttributeCollection[]
  samples: Sample[]
  blocklist: Attribute[]
  suggestedAttributeCollections: AttributeCollection[]
  suggestedSamples: Sample[]
  suggestedTargets: Attribute[]
  suggestedBlocklist: Attribute[]
  blockSubmit: boolean
  sample_mode: boolean
  errors: string[]
  sending: boolean
}

enum CorrelationStateEnum {
  ATTRIBUTECOLLECTIONS = 'attributeCollections',
  SUGGESTEDATTRIBUTECOLLECTIONS = 'suggestedAttributeCollections',
  SUGGESTEDSAMPLES = 'suggestedSamples',
  SUGGESTEDTARGETS = 'suggestedTargets',
  SUGGESTEDBLOCKLIST = 'suggestedBlocklist',
  BLOCKSUBMIT = 'blockSubmit',
  SAMPLE_MODE = 'sample_mode',
  ERRORS = 'errors',
  BLOCKLIST = 'blocklist',
  CREATED_AT = 'created_at',
  FILTEROPTIONS = 'filterOptions',
  FILTERS = 'filters',
  GRAIN = 'grain',
  ID = 'id',
  RANDOMIZE = 'randomize',
  ROW_LIMIT = 'row_limit',
  SAMPLES = 'samples',
  STRATIFICATION_COL_UNIQUE_NAME = 'stratification_col_unique_name',
  TARGET_ATTRIBUTE = 'target_attribute',
  DATA_LOADING_PARAMS = 'data_loading_params',
  SENDING = 'sending',
  INIT = 'init',
}

export { CorrelationStateEnum }
export type { CorrelationState, PageParams }
