import { Box, Typography } from '@northvolt/ui'
import { Analytics } from '@vercel/analytics/react'
import { SpeedInsights } from '@vercel/speed-insights/react'
import Layout from 'components/Layout'
import { useRouteError } from 'react-router-dom'

export default function Route() {
  //@ts-expect-error error actually exists
  const { status, message, data } = useRouteError()
  return (
    <Layout>
      <Box padding={2}>
        <Typography variant='displaySmall'>{status || '404'}</Typography>
        <Typography variant='headlineMedium'>{message || 'Page not found'}</Typography>
        <Typography>{data || ''}</Typography>
        <Analytics />
        <SpeedInsights />
      </Box>
    </Layout>
  )
}
