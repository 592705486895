import { Box, ListItem, Stack, Typography } from '@northvolt/ui'
import type { DatasetLight } from 'client/model'
import type React from 'react'
import { Link } from 'react-router-dom'
import AttributeCollectionChip from '../AttributeCollection/AttributeCollectionChip'
import SampleChip from '../Sample/SampleChip'
import { dateStringDisplayFormat } from '../Utils'

interface DatasetListItemProps {
  dataset: DatasetLight
  minimal?: boolean
}

const DatasetListItem: React.FC<DatasetListItemProps> = ({ dataset, minimal }) => {
  return (
    <ListItem divider sx={{ p: 2, border: '1px solid #E0E0E0', borderRadius: 1 }} key={dataset.id}>
      <Stack alignItems='left' sx={{ overflow: 'hidden', width: '100%' }}>
        <Link to={`/atlas/datasets/${dataset.id}`} style={{ color: 'inherit' }}>
          <Typography title={dataset.name} variant='subtitleMedium'>
            <strong>{dataset.name}</strong>
          </Typography>
        </Link>
        <Typography variant='bodySmall'>{dataset.description}</Typography>
        {!minimal && (
          <>
            <Box>
              <Typography variant='bodySmall'>Attribute Collections:</Typography>
              {dataset.attribute_collections?.map(attrCol => (
                <AttributeCollectionChip key={attrCol.id} attributeCollection={attrCol} clickable />
              ))}
            </Box>
            <Box>
              <Typography variant='bodySmall'>Samples:</Typography>
              {dataset.samples?.map(sample => (
                <SampleChip key={sample.id} sample={sample} clickable />
              ))}
            </Box>
          </>
        )}
        <Typography variant='captionSmall' color='text.secondary' title={dataset.grain}>
          {dataset.grain} Dataset created by {dataset.author.full_name} on{' '}
          {dateStringDisplayFormat(dataset.created_at?.toString(), true)}
        </Typography>
      </Stack>
    </ListItem>
  )
}

export default DatasetListItem
