import ReadMoreIcon from '@mui/icons-material/ReadMore'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import ListItemIcon from '@mui/material/ListItemIcon'
import { IconButton, ListItem, Stack, Tooltip, Typography } from '@northvolt/ui'
import type { DatasetLight } from 'client/model'
import { Link } from 'react-router-dom'
import { getHighlightedText } from './../Utils'

interface DatasetSelectItemProps {
  dataset: DatasetLight
  listItemProps?: any
  searchText?: string
}

const DatasetSelectItem: React.FC<DatasetSelectItemProps> = ({ dataset, listItemProps, searchText }) => {
  const getWarningTriangle = () => {
    const numAttributes = dataset.attribute_collections?.reduce(
      (acc, collection) => acc + (collection.attributes?.length ?? 0),
      0,
    )
    if (numAttributes && numAttributes > 1000) {
      return (
        <ListItemIcon>
          <Tooltip title={`Large Dataset with ${numAttributes} attributes.`}>
            <WarningAmberIcon />
          </Tooltip>
        </ListItemIcon>
      )
    }
    return <></>
  }
  return (
    <ListItem key={dataset.id} divider {...listItemProps}>
      <Stack alignItems='left' sx={{ overflow: 'hidden', width: '100%' }}>
        <Typography title={dataset.name} variant='subtitleMedium'>
          {getHighlightedText(dataset.name, searchText)}
        </Typography>
        <Typography variant='captionSmall' color='text.secondary'>
          {getHighlightedText(dataset.description?.toString(), searchText)}
        </Typography>
        <Typography variant='captionSmall' color='text.secondary' title={dataset.grain}>
          {dataset.grain} Dataset by {dataset.author.full_name}
        </Typography>
      </Stack>
      {getWarningTriangle()}
      <IconButton edge='end' size='small' onClick={e => e.stopPropagation()} title='Read More'>
        <Link to={`/atlas/datasets/${dataset.id}`} style={{ color: 'inherit' }}>
          <ReadMoreIcon />
        </Link>
      </IconButton>
    </ListItem>
  )
}

export default DatasetSelectItem
